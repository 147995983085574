import { ReflectionUtil } from '../../system/utils/reflection.util';

export class BaseStorageEntity<T> {
  public static defaultFields = ['id', 'createdAt', 'updatedAt', 'createdById', 'updatedById'];
  public fields: string[] = BaseStorageEntity.defineFields();
  public domPurifyFields: string[] = [];
  public id: string;
  public createdAt?: Date;
  public updatedAt?: Date;
  public createdById?: string;
  public updatedById?: string;

  static defineFields(fields: string[] = []) {
    return this.defaultFields.concat(fields);
  }
  constructor(props: { [key in keyof T]?: T[key] } = {}) {
    Object.assign(this, props);
  }
  async toStorage(): Promise<Record<string, any>> {
    const storage = {};
    for (const field of this.fields) {
      if (typeof this[field] !== 'undefined') {
        if (this.domPurifyFields.includes(field)) {
          storage[field] = ReflectionUtil.domPurify(this[field]);
        }
        if (this[field] instanceof Date && (field === 'createdAt' || field === 'updatedAt')) {
          storage[field] = this[field].getTime();
        } else {
          storage[field] = this[field];
        }
      }
    }
    return storage;
  }
  async fromStorage(storage: Record<string, any>): Promise<this> {
    if (!storage) {
      throw new Error('unable to unsearlialize from storage as Storage is empty');
    }
    for (const field of this.fields) {
      if (field === 'createdAt' || field === 'updatedAt') {
        this[field] = new Date(storage[field]);
      } else {
        this[field] = storage[field];
      }
    }
    return this;
  }
}
