import { useNavigate } from 'react-router-dom';
import { env } from '../../../env';
import { BeanManager } from '../../system/services/system/bean.manager';
import { ReflectionUtil } from '../../system/utils/reflection.util';
import { MockContextData } from './monday.mock.context.data';
import { type } from 'node:os';
export const STORAGE_KEY = 'Storage';
export const KEY_VERSIONS_KEY = 'KeyVersions';
export type MondayExecuteCommands =
  | 'closeSettings'
  | 'openAppFeatureModal'
  | 'closeAppFeatureModal'
  | 'valueCreatedForUser'
  | 'openItemCardByColumnValue'
  | 'openPlanSelection';
export const toMondayResponse = (data) => {
  return {
    data: {
      ...data,
      success: true
    }
  };
};
export class DataStorage {
  public instance: DataStorage;
  public storage = JSON.parse(localStorage.getItem(this.generateKey(STORAGE_KEY)) || '{}');
  public versions = JSON.parse(localStorage.getItem(this.generateKey(KEY_VERSIONS_KEY)) || '{}');
  constructor(protected key) {}
  generateKey(key: string) {
    return `${this.key}:${key}`;
  }
  syncToLocalStorage() {
    localStorage.setItem(this.generateKey(STORAGE_KEY), JSON.stringify(this.storage));
    localStorage.setItem(this.generateKey(KEY_VERSIONS_KEY), JSON.stringify(this, this.versions));
  }
  async setItem(key, value) {
    if (!this.versions[key]) this.versions[key] = 0;
    this.versions[key]++;
    this.storage[key] = value;
    this.syncToLocalStorage();
    return toMondayResponse({ value: ReflectionUtil.clone(value), version: this.versions[key] });
  }
  async getItem(key) {
    return toMondayResponse({ value: this.storage[key], version: this.versions[key] });
  }
  async deleteItem(key) {
    delete this.storage[key];
    delete this.versions[key];
    this.syncToLocalStorage();
    return toMondayResponse({ value: null, version: 0 });
  }
  print() {
    console.table(this.storage);
  }
  printVersions() {
    console.table(this.versions);
  }
}
export const MondayMock = BeanManager.register(
  class MondayMock {
    public storage: DataStorage;
    constructor() {
      this.storage = new DataStorage('storage-global');
      this.storage.instance = new DataStorage('storage-instance');
    }
    get(key: 'settings' | 'context' | 'itemIds' | 'sessionToken' | 'filter' | 'location') {
      switch (key) {
        case 'settings':
          return this.storage.getItem('monday.mock.settings');
        case 'context':
          return toMondayResponse(MockContextData[env.MOCK_ENV_CONTEXT]);
        case 'itemIds':
          return toMondayResponse([MockContextData[env.MOCK_ENV_CONTEXT].itemId]);
        case 'sessionToken':
          return toMondayResponse('mock-session-token');
        case 'filter':
          return toMondayResponse({
            filters: {
              owners: {
                users: [MockContextData[env.MOCK_ENV_CONTEXT].userId]
              }
            }
          });
      }
    }
    set(
      key: 'settings' | 'context' | 'itemIds' | 'sessionToken' | 'filter' | 'location',
      value: any
    ) {
      switch (key) {
        case 'settings':
          return this.storage.setItem('monday.mock.settings', value);
      }
    }
    execute(command: MondayExecuteCommands, ...params) {
      switch (command) {
        case 'openAppFeatureModal':
          return toMondayResponse((window.location.hash = '/admin-page'));
        case 'closeAppFeatureModal':
          return toMondayResponse((window.location.hash = '/macro/render'));
        case 'valueCreatedForUser':
          console.log('valueCreatedForUser', params);
          return toMondayResponse(null);
        case 'openPlanSelection':
          console.log('openPlanSelection', params);
          return toMondayResponse(null);
      }
    }
    listen(event: string, callback: (data: any) => void) {}
    api(api: string, apiOptions?: any) {
      console.log('invoking monday api', api, apiOptions);
      return toMondayResponse({ boards: [{}], items: [{}] });
    }
  }
);
