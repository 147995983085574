/* eslint-disable quotes */
import { KeyValueStorage } from '../../monday/entities/key.value.entity';
import { StorageRepository } from '../../monday/storage/storage.repository';
import { BeanManager } from '../../system/services/system/bean.manager';
import { TableNames } from '../model/table.names';

const keyValRepository = new StorageRepository(KeyValueStorage<any>);

export const PermissionService = BeanManager.register(
  class PermissionService {
    protected contentPermissionExists = false;
    constructor() {}
    async getContentPolicies() {
      const cKeyVal = await keyValRepository.findById('contentPolicies');
      if (cKeyVal && cKeyVal.value) {
        this.contentPermissionExists = true;
        return cKeyVal.value;
      }
      return [];
    }

    cspListToMap(cspList) {
      const cspMap = {
        'default-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'script-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'style-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'img-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'font-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'object-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'media-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'frame-src': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:'],
        'script-src-elem': ["'unsafe-inline'", "'unsafe-eval'", "'self'", 'blob:', 'data:']
      };
      for (const policy of cspList) {
        for (const key in policy) {
          if (key !== 'url' && policy[key] === true) {
            if (!cspMap[key]) {
              cspMap[key] = [];
            }
            cspMap[key].push(policy['url']);
          }
        }
      }

      return cspMap;
    }
    saveContentPolicies({ enableCSP = false, policies }) {
      this.contentPermissionExists = true;
      return keyValRepository.save(
        new KeyValueStorage({
          key: 'contentPolicies',
          value: { policies, enableCSP }
        })
      );
    }
  }
);
