import { IDataStorage } from '../ifs/idata.storage';
import { MondayItemApi } from '../services/monday.item.api';
import { MondayRuntime } from '../services/monday.runtime';

const runtime = MondayRuntime.instance();
const monday = runtime.getMonday();

export class BigTableStorage implements IDataStorage {
  protected tableKey: string;
  constructor(protected tableName: string) {
    if (!tableName) throw new Error('tableName is not defined in TableStorage constructor');
    if (tableName.match(/[^a-zA-Z0-9_]/))
      throw new Error('tableName can only contain alphanumeric characters and underscores');
    this.tableKey = runtime.generateKey(`BigTable:${tableName}`);
  }
  findAll(query: any): Promise<any[]> {
    throw new Error('Method not implemented.');
  }
  async insert(record: Record<string, any>): Promise<any> {
    if (record.id) {
      if (await this.findById(record.id)) {
        throw new Error(
          'Record with id ' + record.id + ' already exists in table ' + this.tableName
        );
      }
    } else {
      record.id = crypto.randomUUID();
    }
    Object.assign(record, {
      createdAt: new Date().getTime(),
      createdById: runtime.getUserId()
    });
    await this.update(record);
    return record;
  }
  async upsert(record: Record<string, any>) {
    if (!record.id) {
      record.id = crypto.randomUUID();
    }
    return await this.update(record);
  }

  async update(record: Record<string, any>) {
    if (!record.createdAt) {
      record.createdAt = new Date().getTime();
    }
    Object.assign(record, {
      updatedAt: new Date().getTime(),
      updatedById: runtime.getUserId()
    });
    await runtime.setItem(this.generateStorageKey(record.id), record);
    return record;
  }
  async delete(recordId: string) {
    await runtime.deleteItem(this.generateStorageKey(recordId));
  }
  generateStorageKey(recordId: string) {
    return `${this.tableKey}:${recordId}`;
  }
  async findById(recordId: string): Promise<Record<string, any>> {
    return await runtime.getItem(this.generateStorageKey(recordId));
  }
}
