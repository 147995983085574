export default {
  name: 'Figma Embed',
  description: 'Embed a Figma design on a webpage.',
  category: 'Design / Figma',
  tags: ['design', 'figma'],
  active: true,
  icon: 'fa-brands fa-figma',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `
      <div id="embedContainer" style="width: 100%; height: 100%;">
        <iframe style="width: 100%; height: 100%;" frameborder="no" allowfullscreen></iframe>
        <div id="error" style="display: none; color: red;"></div>
      </div>`,
  script: `/** JavaScript */
    $template.ready(async () => {
      const variables = $template.useVariables();
      const designUrl = await variables.getValue('Figma_Design_URL');
      const iframe = document.querySelector('iframe');
      const view = $template.useView();
      view.showLoading();
      iframe.onload = () => {
        view.hideLoading();
      };
      iframe.onerror = () => {
        view.hideLoading();
        // view.showAlert('Error loading video', 'danger');
      }
      const errorElement = document.getElementById('error');
  
      if (designUrl) {
        const designId = designUrl.split('.com/file/')[1].split('/')[0];
        iframe.src = 'https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/file/' + designId;
        errorElement.style.display = 'none';
      } else {
        // Invalid Figma design URL handling
        errorElement.textContent = 'Invalid Figma Design URL: ' + designUrl;
        errorElement.style.display = 'block';
      }
    });`,
  css: `
      #error {
        font-size: 16px;
        padding: 10px;
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
        border-radius: 4px;
        margin: 10px;
      }`,
  variables: [
    {
      label: 'Figma Design URL',
      name: 'Figma_Design_URL',
      type: 'multilineString',
      defaultFieldValue:
        'https://www.figma.com/file/dlQqwKcFRLHG5dg4kZS0IC/Untitled?type=whiteboard&node-id=0-1&t=ClWERuh4nxwTRPCw-0',
      mandatory: true,
      description: 'Figma Design URL',
      defaultVariable: true
    }
  ]
};
