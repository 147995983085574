export default {
  name: 'Html Embed',
  description: `The Html Embed Template is a pre-built template that allows users to easily create and display a html embed .`,
  category: 'Misc / Html',
  tags: ['misc', 'html'],
  active: true,
  icon: 'fa-solid fa-btn',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe width="100%" height="100%" frameborder="0" id="html-frame" class="html-frame"></iframe>`,
  script: `/** JavaScript */
  $template.ready(async () => {
      const iframe = document.getElementById('html-frame');
      const variables = $template.useVariables(); // get variables service
      const html = await variables.getValue('html');
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(html);
      iframe.contentWindow.document.close();
  });`,
  css: ``,
  variables: [
    {
      label: 'Html',
      name: 'html',
      type: 'script',
      defaultFieldValue: `<html>
      <h1 style="color:Red">Hello World!</h1>
      <h1 style="color:Orange">Hello World!</h1>
      <h1 style="color:Yellow">Hello World!</h1>
      <h1 style="color:Green">Hello World!</h1>
      <h1 style="color:Blue">Hello World!</h1>
      <h1 style="color:Indigo">Hello World!</h1>
      <h1 style="color:Violet">Hello World!</h1>
      </html>`,
      mandatory: true,
      settings: {
        language: 'html'
      },
      description: 'Html to embed'
    }
  ]
};
