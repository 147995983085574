import googleDoc from './google/google.doc';
import googleForm from './google/google.form';
import googleMap from './google/google.map';
import googleSheet from './google/google.sheet';
import googleSlide from './google/google.slide';
import youtube from './google/youtube';
import facebookEmbed from './meta/facebook.embed';
import instaEmbed from './meta/insta.embed';
import button from './misc/button';
import celebration from './misc/celebration';
import codepen from './misc/codepen';
import embedIframe from './misc/embed.iframe';
import figma from './misc/figma';
import htmlEmbed from './misc/html.embed';
import imageSlider from './misc/image.slider';
import mermaid from './misc/mermaid';
import soundCloud from './misc/sound.cloud';
import stopwatch from './misc/stopwatch';
import swagger from './misc/swagger';
import textBanner from './misc/text.banner';
import vimeo from './misc/vimeo';
import wyswygEditor from './misc/wyswyg.editor';
import twitterEmbed from './twitter/twitter.embed';

const exampleTemplates = [
  googleMap,
  googleSheet,
  googleDoc,
  googleForm,
  googleSlide,
  // googleAnalytics,
  youtube,
  swagger,
  embedIframe,
  vimeo,
  textBanner,
  button,
  // stopwatch,
  celebration,
  mermaid,
  imageSlider,
  codepen,
  soundCloud,
  figma,
  // instaFeed,
  instaEmbed,
  facebookEmbed,
  htmlEmbed,
  wyswygEditor
  // facebookFeed,
  // twitterEmbed
  // issueList,
  // issueChart,
]
  .map((t: any) => ({
    ...t,
    id: t.id || (t.category + '_' + t.name).replace(/[\W_]+/g, '_').toLowerCase(),
    type: t.type || 'example',
    active: true,
    exampleGenerated: true,
    tags: t['tags'] || [],
    javascript: t.script,
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: 'system',
    updatedBy: 'system',
    version: 0
  }))
  .sort((a, b) => a.name.localeCompare(b.name));
export const populateContents = (template) => {
  template['$htmlId'] = {
    value: {
      html: template.html?.toString(),
      css: template.css,
      javascript: template.script?.toString()
    }
  };
  template.variables = template.variables?.map((v) => ({
    ...v,
    settings: v.settings || {},
    label: v.label || v.name.replaceAll('_', ' ')
  }));
  return template;
};
export const getExampleTemplates = (withContents = false) => {
  if (withContents) {
    exampleTemplates.map(populateContents);
  }
  return exampleTemplates;
};

export const getExampleTemplateById = (id: string, withContents = false) => {
  const template = exampleTemplates.find((template) => template.id === id);
  if (template && withContents) {
    return populateContents(template);
  }
  return template;
};
