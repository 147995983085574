export default {
  name: 'SoundCloud Embed',
  description: 'Embed a SoundCloud track or playlist on a webpage.',
  category: 'Music / SoundCloud',
  tags: ['music', 'soundcloud'],
  active: true,
  icon: 'fa-brands fa-soundcloud',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `
      <div id="embedContainer" style="width: 100%; height: 100%;">
        <iframe style="width: 100%; height: 100%;" scrolling="no" frameborder="no" allow="autoplay"></iframe>
        <div id="error" style="display: none; color: red;"></div>
      </div>`,
  script: `/** JavaScript */
    $template.ready(async () => {
      const variables = $template.useVariables();
      const trackOrPlaylistUrl = await variables.getValue('SoundCloud_Track_Or_Playlist_URL');
      const iframe = document.querySelector('iframe');
      const view = $template.useView();
      view.showLoading();
      iframe.onload = () => {
        view.hideLoading();
      };
      iframe.onerror = () => {
        view.hideLoading();
        // view.showAlert('Error loading video', 'danger');
      }
      const errorElement = document.getElementById('error');
      
      if (trackOrPlaylistUrl) {
        iframe.src = 'https://w.soundcloud.com/player/?url=' + encodeURIComponent(trackOrPlaylistUrl);
        errorElement.style.display = 'none';
      } else {
        // Invalid SoundCloud track or playlist URL handling
        errorElement.textContent = 'Invalid SoundCloud Track Or Playlist URL: ' + trackOrPlaylistUrl;
        errorElement.style.display = 'block';
      }
    });`,
  css: `
      #error {
        font-size: 16px;
        padding: 10px;
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
        border-radius: 4px;
        margin: 10px;
      }`,
  variables: [
    {
      label: 'SoundCloud URL',
      name: 'SoundCloud_Track_Or_Playlist_URL',
      type: 'multilineString',
      defaultFieldValue: 'https://soundcloud.com/evelina-kanafieva/pharrell-williams-happy',
      mandatory: true,
      description: 'SoundCloud Track Or Playlist URL',
      defaultVariable: true
    }
  ]
};
