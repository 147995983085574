import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Input,
  Popover,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip
} from 'antd';
import { useEffect, useState } from 'react';
import { TemplateService } from '../../../services/template.service';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Meta from 'antd/es/card/Meta';
import {
  EyeOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DislikeOutlined,
  LikeOutlined,
  StarOutlined
} from '@ant-design/icons';
import IframeRenderer from '../../editor/IframeRenderer';
import { FullScreen } from '../../../../system/components/fullscreen';
import { GtmService } from '../../../../system/services/stats/gtm.service';
import { PermissionService } from '../../../services/csp.service';
import { useDebounce } from '../../../../system/hooks/useDebounce';

const templateService = TemplateService.instance();
const permissionService = PermissionService.instance();
const gridStyle: React.CSSProperties = {
  width: '25%',
  // minHeight: '500px',
  textAlign: 'center',
  boxShadow: 'none'
};

export const TemplateLibrary = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');

  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cspSettings, setCSPSettings] = useState({ enableCSP: false, policies: [] });
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const [examples, csps] = await Promise.all([
          templateService.getAllExamples(),
          permissionService.getContentPolicies()
        ]);
        setCSPSettings(csps);
        setTemplates(examples);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
      // setTimeout(() => {
      //   //@ts-ignore
      //   document.getElementsByClassName('template-library')[0].style.height =
      //     document.body.offsetHeight + 'px';
      // }, 500);
    })();
    GtmService.instance().pageview('/templates/library');
  }, []);
  // Queries
  const query = useQuery({
    queryKey: [templates, searchText],
    queryFn: async () => {
      return templates.filter((r) => {
        if (searchText.toLocaleLowerCase().length > 0) {
          return r.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0;
        }
        return true;
      });
    }
  });
  const searchDebounce = useDebounce(500);
  return (
    <div className="list template-library" style={{ padding: '10px' }}>
      <Card
        className="main-card"
        hoverable={false}
        loading={loading}
        title=""
        bordered={false}
        extra={
          <Badge count={searchText.trim() !== '' ? '*' : ''} status="warning" color="blue">
            <Space>
              <span>
                showing {query.data?.length} of {templates.length}
              </span>
              <Input.Search
                onChange={(e) => {
                  searchDebounce(() => {
                    setSearchText(e.target.value);
                  });
                }}
                placeholder="search"
                onSearch={(value) => {
                  setSearchText(value);
                }}
                style={{
                  width: 200
                }}
              />
            </Space>
          </Badge>
        }>
        {query.data?.map((template, index) => (
          <Card.Grid hoverable={false} style={gridStyle} key={index}>
            <Card
              hoverable
              onDoubleClick={() => {
                navigate(`/templates/new/edit?sourceId=${template.id}`);
              }}
              style={{ textAlign: 'center', padding: 10 }}
              // cover={<IframeRenderer autoExecute={true} template={template} />}
              actions={[
                <Popover
                  key={index}
                  placement="topLeft"
                  title={template.name}
                  content={() => (
                    <div style={{ width: '600px', height: '400px' }}>
                      <FullScreen>
                        <IframeRenderer
                          template={template}
                          cspSettings={cspSettings}
                          autoExecute={true}
                        />
                      </FullScreen>
                    </div>
                  )}
                  trigger="click">
                  <Button
                    title="Preview"
                    style={{ width: '100%', height: '100%' }}
                    type="link"
                    icon={<EyeOutlined />}></Button>
                </Popover>,
                <Button
                  key={index}
                  style={{ width: '100%', height: '100%' }}
                  icon={<EditOutlined />}
                  title="Edit"
                  type="link"
                  onClick={() => {
                    navigate(`/templates/new/edit?sourceId=${template.id}`);
                  }}></Button>,
                <Button
                  key={index}
                  style={{ width: '100%', height: '100%' }}
                  icon={<LikeOutlined />}
                  disabled
                  title="Like (Feature coming soon)"
                  type="link"
                  onClick={() => {}}></Button>,
                <Button
                  key={index}
                  style={{ width: '100%', height: '100%' }}
                  icon={<DislikeOutlined />}
                  disabled
                  title="Dislike (Feature coming soon)"
                  type="link"
                  onClick={() => {}}></Button>,
                <Button
                  key={index}
                  style={{ width: '100%', height: '100%' }}
                  icon={<StarOutlined />}
                  disabled
                  title="Favorite (Feature coming soon)"
                  type="link"
                  onClick={() => {}}></Button>
              ]}>
              <Meta
                style={{ width: '100%', paddingBottom: 5 }}
                title={template.name}
                description={
                  <div className="description">
                    <div className="category">{template.category}</div>
                    <div className="tags">
                      {template.tags.map((tagName, i) => (
                        <Tag key={i} color={templateService.getTagColor(tagName)}>
                          {tagName}
                        </Tag>
                      ))}
                    </div>
                  </div>
                }
              />
              {template.description}
            </Card>
          </Card.Grid>
        ))}
      </Card>
    </div>
  );
};
