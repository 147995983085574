import { useEffect, useRef, useState } from 'react';
import { ITemplateEntity } from '../../../model/itemplate';
import { MondayRuntime } from '../../../../monday/services/monday.runtime';
import { TemplateService } from '../../../services/template.service';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { FullScreen } from '../../../../system/components/fullscreen';
import IframeRenderer from '../../editor/IframeRenderer';
import {
  Button,
  Card,
  Checkbox,
  FloatButton,
  FormInstance,
  Modal,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Tabs,
  Tooltip,
  Tour,
  TourProps,
  Typography
} from 'antd';
import { Allotment } from 'allotment';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationService } from '../../../../system/services/ui/notification.service';
import { PermissionService } from '../../../services/csp.service';
import {
  CloudUploadOutlined,
  SaveOutlined,
  RollbackOutlined,
  CheckOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { HtmlTemplateEntity } from '../../../entities/html.template.entity';
import { run } from 'node:test';
import VariableForm, { Variable } from '../../variable-config/variable.form';
import VariableConfiguration from './VariableConfiguration';
import { useDebounce } from '../../../../system/hooks/useDebounce';
import { ReactIf } from '../../../../system/components/conditional/r.if';
import TabPane from 'antd/es/tabs/TabPane';
import { OtherConfigurations } from './OtherConfigurations';
import { TemplateRenderer } from '../../admin/template/TemplateRenderer';
import { ITemplateConfig } from '../../../model/itemplate.config';
import { GtmService } from '../../../../system/services/stats/gtm.service';
import { TourService } from '../../../../system/services/ui/tour.service';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { set } from 'js-cookie';
import {
  UserJourneyEvent,
  UserJourneyService
} from '../../../../system/services/ui/user.journey.service';

export interface IConfigRendererProps {
  templateId?: string;
}
const { Text, Link } = Typography;
const runtime = MondayRuntime.instance();
const notification = NotificationService.instance();
const templateService = TemplateService.instance();
const permissionService = PermissionService.instance();
const tourService = TourService.instance();
const userJourney = UserJourneyService.instance();

export function TemplateConfiguration({ templateId }: IConfigRendererProps) {
  const [template, setTemplate] = useState<ITemplateEntity>(templateService.emptyTemplate());
  const [cspSettings, setCspSettings] = useState<any>({ enableCSP: false, policies: [] });
  const [templateConfig, setTemplateCOnfig] = useState<ITemplateConfig>({});
  const [loading, setLoading] = useState({ templateLoading: false, settingsLoading: true });
  const [templates, setTemplates] = useState<HtmlTemplateEntity[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const variableFormRef = useRef<FormInstance<Variable>>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const templateChangeDebounce = useDebounce(500);
  if (!templateId) {
    templateId = id;
  }
  //const { width, height } = useWindowSize();
  const [openTour, setOpenTour] = useState(!tourService.isUserVisitedTour('template-config'));
  const tourRefs = {
    backBtn: useRef(null),
    editBtn: useRef(null),
    applyBtn: useRef(null),
    templateSelector: useRef(null),
    variableConfig: useRef(null),
    otherConfig: useRef(null),
    preview: useRef(null),
    templateConfig: useRef(null),
    includeExample: useRef(null),
    refreshButton: useRef(null)
  };
  const steps: TourProps['steps'] = [
    {
      title: 'Go Back',
      description: 'Click here to go back to the template view',
      target: tourRefs.backBtn.current
    },
    {
      title: 'Select Template',
      description: 'Select the template from the dropdown list',
      target: tourRefs.templateSelector.current
    },
    {
      title: 'Include Example',
      description: 'If you want to include example templates, check this box',
      target: tourRefs.includeExample.current
    },
    {
      title: 'Refresh',
      description: 'In case of some issues you can refresh the configuration page from here',
      target: tourRefs.refreshButton.current
    },
    {
      title: 'Edit Template',
      description: 'Click here to edit the template in a popup',
      target: tourRefs.editBtn.current
    },
    {
      title: 'Apply Template',
      description: 'Click here to apply the template on this view',
      target: tourRefs.applyBtn.current
    },
    {
      title: 'All Set!',
      description: (
        <div>
          <Title level={3}>Dive right in!</Title>
          <Paragraph>
            Choose a template from the dropdown to begin your creative journey. If it's your first
            time, fret not! You can craft a new template from our vibrant library or simply toggle
            on the 'Include examples' for a smooth start.
          </Paragraph>
        </div>
      ),
      target: tourRefs.templateSelector.current
    }
    // {
    //   title: 'Variable Configuration',
    //   description: 'Configure the variables',
    //   target: tourRefs.variableConfig.current
    // },
    // {
    //   title: 'Other Configurations',
    //   description: 'Configure other settings (e.g. Show/Hide config button)',
    //   target: tourRefs.otherConfig.current
    // },
    // {
    //   title: 'Preview',
    //   description: 'Preview the template',
    //   target: tourRefs.preview.current
    // }
  ];
  useAsyncEffect(async () => {
    GtmService.instance().pageview(`/templates/configuration`);
    setLoading((loading) => ({ ...loading, settingsLoading: true }));
    try {
      const [settings, csps] = await Promise.all([
        templateService.getTemplateConfig(),
        permissionService.getContentPolicies()
      ]);
      setTemplateCOnfig(settings);
      setCspSettings(csps);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((loading) => ({ ...loading, settingsLoading: false }));
    }
  }, []);
  useAsyncEffect(async () => {
    const allTemplates = await templateService.getAllTemplates({
      includeExamples: templateConfig.includeExamples
    });
    setTemplates(
      allTemplates.filter((t) => typeof t.publishedAt !== 'undefined' || t.type === 'example')
    );
  }, [templateConfig.includeExamples]);
  useAsyncEffect(async () => {
    if (!templateId || templateId === 'new') {
      return;
    }
    setLoading((loading) => ({ ...loading, templateLoading: true }));
    try {
      const configuredTemplate = await templateService.getTemplateById(templateId, true, {
        includeExamples: true
      });
      setTemplate(configuredTemplate);
      setTemplateCOnfig((config) => ({ ...config, templateId }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((loading) => ({ ...loading, templateLoading: false }));
    }
  }, [templateId]);
  useEffect(() => {
    if (
      loading.settingsLoading === false &&
      loading.templateLoading === false &&
      templateConfig.templateId &&
      template.id === templateId
    ) {
      // check if template and settings has been loaded
      const variables = template.variables || [];
      const config: ITemplateConfig = templateConfig || {};
      if (!config.variables) {
        config.variables = {};
      }
      const variableConfig: Variable[] = variables.map((variable) => {
        let value = variable.defaultFieldValue;
        if (
          config.templateId === template.id &&
          typeof config.variables[variable.name] !== 'undefined'
        ) {
          value = config.variables[variable.name];
        }
        if (!variable.settings) {
          variable.settings = {};
        }
        return {
          ...variable,
          value
        };
      });
      setTemplate((template) => ({ ...template, variables: variableConfig }));
      setIsInitialized(true);
      if (variableFormRef.current) {
        variableFormRef.current.setFieldsValue(
          variableConfig.reduce((acc, variable) => {
            acc[variable.name] = variable.value;
            return acc;
          }, {})
        );
      }
    }
  }, [loading, templateId]);
  return (
    <div className="template-configuration">
      {contextHolder}
      <Skeleton
        loading={loading.settingsLoading || loading.templateLoading}
        active={true}
        paragraph={{ rows: 10 }}>
        <Row
          className="menu-component"
          style={{ paddingBottom: '5px', display: 'flex', marginRight: '180px' }}>
          <Space className="left-menu" style={{ display: 'flex' }}>
            <Tooltip title="Back to Template">
              <Button
                ref={tourRefs.backBtn}
                className="back-btn"
                type="link"
                size="large"
                icon={<i className="fa fa-arrow-circle-left"></i>}
                onClick={() => {
                  navigate('/macro/render');
                }}></Button>
            </Tooltip>
            <div className="template-select" ref={tourRefs.templateSelector}>
              <span className="title">Select Template </span>
              <Select
                style={{ minWidth: '200px' }}
                showSearch={true}
                placeholder="Search to Select"
                options={
                  templateConfig.includeExamples
                    ? templates.reduce(
                        (acc, t) => {
                          if (t.type === 'example') {
                            acc[1].options.push({ label: t.name, value: t.id });
                          } else {
                            acc[0].options.push({ label: t.name, value: t.id });
                          }
                          return acc;
                        },
                        [
                          { label: 'User Defined', options: [] },
                          { label: 'Examples', options: [] }
                        ]
                      )
                    : [
                        {
                          label: 'User Defined',
                          options: templates.map((t) => ({ label: t.name, value: t.id }))
                        }
                      ]
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                value={template.id}
                onChange={(value) => {
                  navigate(`/templates/${value}/configure`);
                }}></Select>
            </div>
            <div className="include-example" ref={tourRefs.includeExample}>
              <Checkbox
                checked={templateConfig.includeExamples}
                onChange={(e) => {
                  setTemplateCOnfig((config) => ({
                    ...config,
                    includeExamples: e.target.checked
                  }));
                }}>
                Include Example
              </Checkbox>
            </div>

            <div></div>
          </Space>
        </Row>
        <Row
          className="side-menu all-code-box-controls"
          style={{
            position: 'absolute',
            top: '5px',
            right: '10px',
            zIndex: 1,
            display: 'flex',
            fontSize: '22px'
          }}>
          <Tooltip title="Refresh">
            <Button
              ref={tourRefs.refreshButton}
              icon={<i className="fa fa-refresh"></i>}
              type="link"
              onClick={async () => {
                window.location.reload();
              }}></Button>
          </Tooltip>
          <Space>
            <Tooltip title="Edit template">
              <Button
                ref={tourRefs.editBtn}
                disabled={!template.id}
                icon={<i className="fa fa-edit"> </i>}
                onClick={async () => {
                  if (template.type === 'example') {
                    modal.confirm({
                      title: 'Cannot edit example template',
                      content:
                        'Clone the example from the Template Library and then make changes to it.',
                      okText: 'Clone',
                      cancelText: 'Cancel',
                      onOk: () => {
                        runtime.openFeatureModal({
                          urlPath: `/templates/new/edit?sourceId=${template.id}&hideBackButton=true`,
                          width: 1200,
                          height: 800
                        });
                      },
                      onCancel: () => {}
                    });
                    return;
                  }
                  const result = runtime.openFeatureModal({
                    urlPath: `/templates/${template.id}/edit?hideBackButton=true`,
                    width: 1200,
                    height: 800
                  });
                  if (result) {
                    //  window.location.reload();
                  }
                }}>
                Edit
              </Button>
            </Tooltip>
            <Button
              type="primary"
              ref={tourRefs.applyBtn}
              icon={<CheckOutlined />}
              disabled={!template.id}
              onClick={async () => {
                try {
                  await variableFormRef.current?.submit();
                  await variableFormRef.current?.validateFields();
                } catch (e) {
                  console.error(e);
                  notification.error({
                    key: 'saving',
                    message: 'Please fix the errors before saving'
                  });
                  return;
                }
                modal.confirm({
                  title: 'Are you sure?',
                  icon: <i className="fa fa-cloud-upload" />,
                  content: <div>Apply "{template.name}" Template on this view </div>,
                  okText: 'Ok',
                  cancelText: 'Cancel',
                  onOk: async () => {
                    notification.info({ key: 'saving', message: 'Saving configuration...' });
                    try {
                      await templateService.saveTemplateConfig({
                        ...templateConfig,
                        variables: template.variables.reduce((acc, variable) => {
                          acc[variable.name] = variable.value;
                          return acc;
                        }, {})
                      });
                      notification.success({
                        key: 'saving',
                        message: 'Configurations saved successfully'
                      });
                      GtmService.instance().pageview(`/templates/${template.name}/configured`);
                      userJourney.logEvent(UserJourneyEvent.TEMPLATE_CONFIGURED, {
                        templateId: template.id,
                        templateName: template.name
                      });
                      await navigate('/macro/render');
                    } catch (e) {
                      console.error(e);
                      notification.error({
                        key: 'saving',
                        message: 'Failed to save Configurations, Please try again later'
                      });
                    }
                  }
                });
              }}>
              Apply
            </Button>
          </Space>
        </Row>
        <ReactIf condition={!templateId || templateId === 'new'}>
          <Result
            status={404}
            title={
              <div style={{ textAlign: 'left', marginLeft: '30px' }}>
                Select a template from the dropdown to configure
              </div>
            }
            subTitle={
              <div style={{ textAlign: 'left', marginLeft: '30px' }}>
                <ul>
                  <li>Choose a template from the dropdown to begin your creative journey.</li>
                  <li>
                    If it's your first time, don't worry! You can craft a new template from our
                    vibrant library or simply toggle the 'Include examples' option for a smooth
                    start.
                  </li>
                  <li>
                    After selecting a template, you can configure its variables and other settings.
                  </li>
                  <li>
                    Once you're satisfied, click the 'Apply' button to apply the template to this
                    view.
                  </li>
                </ul>
                <br />
                <Text strong>Note: Only published templates can be applied.</Text>
              </div>
            }
          />
        </ReactIf>
        <ReactIf condition={!!template.id}>
          <div className="ide-body">
            <Allotment defaultSizes={[300, 200]}>
              <Allotment.Pane>
                <FullScreen>
                  <div className="template-configure-wrapper" style={{ padding: '10px' }}>
                    <Tabs
                      defaultActiveKey="1"
                      items={[
                        {
                          key: 'variables',
                          label: (
                            <div
                              className="tour-step variable-config"
                              ref={tourRefs.variableConfig}>
                              <Space>
                                <i className="fa fa-wrench" />
                                Variable Configurations
                              </Space>
                            </div>
                          ),
                          children: (
                            <ReactIf condition={isInitialized}>
                              <VariableConfiguration
                                variables={template.variables || []}
                                onSubmit={() => {}}
                                onInit={(form) => {
                                  variableFormRef.current = form;
                                }}
                                onChange={(variables) => {
                                  templateChangeDebounce(() => {
                                    setTemplate((template) => ({ ...template, variables }));
                                  });
                                }}
                              />
                            </ReactIf>
                          )
                        },
                        {
                          key: 'settings',
                          label: (
                            <div className="tour-step other-config" ref={tourRefs.otherConfig}>
                              <Space>
                                <i className="fa fa-cog" />
                                Settings
                              </Space>
                            </div>
                          ),
                          children: (
                            <ReactIf condition={isInitialized}>
                              <OtherConfigurations
                                config={templateConfig}
                                onChange={(config) => {
                                  setTemplateCOnfig(config);
                                }}
                              />
                            </ReactIf>
                          )
                        }
                      ]}></Tabs>
                  </div>
                </FullScreen>
              </Allotment.Pane>
              <Allotment.Pane>
                <div className="template-preview" ref={tourRefs.preview}>
                  <Card title="Preview" className="configure-card">
                    <FullScreen>
                      <TemplateRenderer
                        debounce={500}
                        template={template}
                        cspSettings={cspSettings}
                        config={templateConfig}
                      />
                    </FullScreen>
                  </Card>
                </div>
              </Allotment.Pane>
            </Allotment>
          </div>
        </ReactIf>
      </Skeleton>

      <Tour
        open={openTour}
        onFinish={() => {
          userJourney.logEvent(UserJourneyEvent.TOUR_COMPLETED, {
            tourId: 'template-config'
          });
          tourService.endTour('template-config');
          setOpenTour(false);
        }}
        onClose={() => {
          setOpenTour(false);
          tourService.endTour('template-config');
        }}
        steps={steps}
      />
    </div>
  );
}
