import { BeanManager } from '../../system/services/system/bean.manager';
import { MondayRuntime } from './monday.runtime';

const runtime = MondayRuntime.instance();

export const MondayItemApi = BeanManager.register(
  class MondayItemApi {
    async createItem(boardId: string, groupId: string, name: string, fields: Record<string, any>) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        create_item (board_id: ${boardId}, group_id: ${groupId}, item_name: "${name}", column_values: \"${JSON.stringify(
          fields
        ).replace(/"/g, '\\"')}\"}{id})`,
        { apiVersion: '2023-10' }
      );
    }
    async findItems(boardId: string, groupId: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `query {
        boards (ids: [${boardId}]) {items (limit: 25, group_id: ${groupId}) {id name column_values {id text value}}}
      }`,
        { apiVersion: '2023-10' }
      );
    }
    async findItemsByColumnValue(boardId: string, columnId: string, value: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `query {
        boards (ids: [${boardId}]) {items (limit: 25, column_values: {id: ${columnId}, text: "${value}"}) {id name column_values {id text value}}}
      }`,
        { apiVersion: '2023-10' }
      );
    }
    async findByItemName(boardId: string, name: string) {
      const monday = runtime.getMonday();
      const result = await monday.api(
        `query {
        items_page_by_column_values (limit: 50, board_id: ${boardId}, columns: [{column_id: "name", column_values: ["${name}"]}]) {
          cursor
          items {
            id
            name
          }
        }
      }`,
        { apiVersion: '2023-10' }
      );
      if (result.data.items_page_by_column_values.items.length === 0) return null;
      return result.data.items_page_by_column_values.items;
    }

    async findItemById(id: string) {
      const monday = runtime.getMonday();
      const result = await monday.api(
        `query {
        items (ids: [${id}]) {id name column_values {id text value}}
      }`,
        { apiVersion: '2023-10' }
      );
      if (result.data.items.length === 0) return null;
      return result.data.items[0];
    }
    async updateItem(boardId: string, id: string, fields: Record<string, any>) {
      const monday = runtime.getMonday();
      const apiString = `mutation($columnValues: JSON!) {
        change_multiple_column_values (board_id: ${boardId}, item_id: ${id}, column_values: $columnValues){id name column_values {id text value}}
      }`;
      // console.log(apiString);
      return monday.api(apiString, {
        apiVersion: '2023-10',
        variables: {
          columnValues: JSON.stringify(fields)
        }
      });
    }
    async deleteItem(id: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        delete_item (item_id: ${id}){id}
      }`,
        { apiVersion: '2023-10' }
      );
    }
    async archiveItem(id: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        archive_item (item_id: ${id}){id}
      }`,
        { apiVersion: '2023-10' }
      );
    }
  }
);
