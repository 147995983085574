import { FuturePromise } from '../../system/beans/event/future.promise';
import { BeanManager } from '../../system/services/system/bean.manager';
import { ReflectionUtil } from '../../system/utils/reflection.util';
import { MondayRuntime } from './monday.runtime';

const runtime = MondayRuntime.instance();
export const INSTALLATION_ID_KEY = `${runtime.getAppId()}:app-installation-id`;
export const AppInstallationService = BeanManager.register(
  class AppInstallationService {
    protected installationPromise = new FuturePromise();
    async getInstallationId() {
      const installationId = await runtime.getItem(INSTALLATION_ID_KEY);
      return installationId;
    }
    async initInstallation() {
      let installationId = await this.getInstallationId();
      const savedInstallationId = localStorage.getItem(INSTALLATION_ID_KEY);
      if (!installationId) {
        console.log('No installation id found, creating new one');
        installationId = ReflectionUtil.uuid();
        await this.clearAllKeys();
        await runtime.setItem(INSTALLATION_ID_KEY, installationId);
        localStorage.setItem(INSTALLATION_ID_KEY, installationId);
        return;
      }
      if (savedInstallationId + '' !== installationId + '') {
        console.log('Installation id mismatch, clearing all keys');
        await this.clearAllKeys();
        localStorage.setItem(INSTALLATION_ID_KEY, installationId);
      }
      await this.installationPromise.resolve(installationId);
    }
    async clearAllKeys() {
      localStorage.clear();
    }
    async waitForInstallation() {
      return this.installationPromise.wait();
    }
  }
);
