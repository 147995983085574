import React, { useEffect, useRef, useState } from 'react';
import IframeRenderer from '../../editor/IframeRenderer';
import htmlService from '../../../services/html.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TemplateRenderer } from '../../admin/template/TemplateRenderer';
import ErrorPage from '../../../../../error-page';
import { Button, FloatButton, Result, Skeleton, Tooltip } from 'antd';
import { MondayRuntime } from '../../../../monday/services/monday.runtime';
import { TemplateService } from '../../../services/template.service';
import { ITemplateEntity } from '../../../model/itemplate';
import { useAsyncEffect } from '../../../../system/hooks/use.async.effect';
import { ITemplateConfig } from '../../../model/itemplate.config';
import {
  UserJourneyEvent,
  UserJourneyService
} from '../../../../system/services/ui/user.journey.service';

const runtime = MondayRuntime.instance();
const templateService = TemplateService.instance();
interface InstanceConfig {
  templateConfig?: ITemplateConfig;
  template?: ITemplateEntity;
}
export default function MacroRenderer() {
  const [loading, setLoading] = useState(true);
  const [instanceConfigs, setInstanceConfigs] = useState<Record<string, InstanceConfig>>({});
  const [activeInstanceId, setActiveInstanceId] = useState<string | null>(null);
  const [context, setContext] = useState<any>({});
  const location = useLocation();

  const navigate = useNavigate();
  useAsyncEffect(async () => {
    if (activeInstanceId) {
      try {
        if (!instanceConfigs[activeInstanceId]) {
          setLoading(true);
          let template = null;
          const templateConfig = await templateService.getTemplateConfig();
          if (templateConfig.templateId) {
            template = await templateService.getTemplateById(templateConfig.templateId);
            template.variables = (template.variables || []).map((v) => {
              if (typeof templateConfig.variables[v.name] !== 'undefined') {
                v.value = templateConfig.variables[v.name];
              }
              return v;
            });
          }
          setInstanceConfigs((prev) => {
            return {
              ...prev,
              [activeInstanceId + '']: { templateConfig, template }
            };
          });
          UserJourneyService.instance().logEvent(UserJourneyEvent.MACRO_RENDERED, {
            templateId: templateConfig.templateId
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  }, [activeInstanceId]);
  useAsyncEffect(async () => {
    const context = await runtime.getContext();
    setContext(context);
    // console.log('context', context);
    setActiveInstanceId(context.instanceId + ''); // convert to string
  }, [location]);
  if (loading) {
    return <Skeleton active></Skeleton>;
  }

  return Object.keys(instanceConfigs).map((instanceId) => {
    const { templateConfig, template } = instanceConfigs[instanceId];
    // console.log(activeInstanceId === instanceId, activeInstanceId, instanceId);
    const display = activeInstanceId === instanceId ? 'block' : 'none';
    if (!templateConfig.templateId) {
      return (
        <div
          data-instance-id={instanceId}
          data-active-instance-id={activeInstanceId}
          key={instanceId}
          className="macro-renderer"
          style={{ display }}>
          <Result
            title="Html template not configured."
            subTitle={
              <div>
                Click &nbsp;
                <a
                  type="link"
                  onClick={() => {
                    if (context?.user?.isGuest) {
                      return;
                    }
                    navigate(`/templates/new/configure`);
                  }}>
                  here
                </a>
                &nbsp; to configure
              </div>
            }
          />
          <Tooltip title="Configure">
            <FloatButton
              className="configure-template-btn"
              style={{
                display:
                  !context?.user?.isGuest && templateConfig.enableConfigAction ? 'block' : 'none'
              }}
              onClick={() => {
                if (context?.user?.isGuest) {
                  return;
                }
                navigate(`/templates/${templateConfig.templateId || 'new'}/configure`);
              }}
              icon={<i className="fa fa-cog"></i>}
            />
          </Tooltip>
        </div>
      );
    }
    return (
      <div
        data-instance-id={instanceId}
        key={instanceId}
        data-active-instance-id={activeInstanceId}
        className="macro-renderer"
        style={{ display }}>
        <TemplateRenderer template={template} config={templateConfig}></TemplateRenderer>
      </div>
    );
  });
}
