export default {
  name: 'Open API - Swagger UI',
  id: 'misc_open_api_open_api',
  description: `The SwaggerAPI HTML Template is a pre-built template that allows users to easily create and display interactive API documentation using the Swagger framework. It provides a simple and intuitive interface for developers to explore and test APIs, and can be customized to suit the needs of any project.`,
  category: 'Misc / Open API',
  active: true,
  tags: ['api', 'swagger', 'openapi'],
  icon: 'fa-solid fa-youtube',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<!DOCTYPE html>
  <html lang="en">
  
  <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content="SwaggerUI" />
      <title>SwaggerUI</title>
      <link rel="stylesheet" href="https://unpkg.com/swagger-ui-dist@4.5.0/swagger-ui.css" />
  </head>
  
  <body style="overflow:scroll;">
      <div id="swagger-wrapper">
          <div id="swagger-ui"></div>
      </div>
      <script src="https://unpkg.com/swagger-ui-dist@4.5.0/swagger-ui-bundle.js"></script>
  </body>
  </html>`,
  script: `/** JavaScript */
  $template.ready(async () => {
    const config = $template.useConfig();
    const variables = $template.useVariables();
    const url = await variables.getValue('swaggerURL');
    window.ui = SwaggerUIBundle({
      url,
      dom_id: '#swagger-ui',
    });
  })`,
  css: ``,
  variables: [
    {
      name: 'swaggerURL',
      type: 'multilineString',
      defaultFieldValue: 'https://petstore3.swagger.io/api/v3/openapi.json',
      mandatory: true,
      description: 'Swagger JSON URL'
    }
  ]
};
