export default {
  name: 'Google Map',
  description: `The Google Maps template example includes the necessary HTML, CSS, and JavaScript code to display a Google Map . It allows users to easily customize the map by specifying the location, zoom level, and map type. This example creates a map that's centered on Sydney, New South Wales, Australia.`,
  category: 'Google / Map',
  active: true,
  tags: ['map', 'google'],
  icon: 'fa-solid fa-map-location-dot',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<!doctype html>
  <!--
   @license
   Copyright 2019 Google LLC. All Rights Reserved.
   SPDX-License-Identifier: Apache-2.0
  -->
  <html>
    <head>
      <title>Simple Map</title>
      <script src="https://polyfill.io/v3/polyfill.min.js?features=default"></script>
      <!-- jsFiddle will insert css and js -->
    </head>
    <body>
      <div id="map"></div>
      <!-- prettier-ignore -->
      <script>(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})
          ({key: "", v: "weekly"});</script>
    </body>
  </html>
  `,
  script: `
  
  $template.ready(async () => {
    const view = $template.useView();
    view.showLoading();  
    const variables = await $template.useVariables();
    const lat = parseInt(await variables.getValue('lat'));
    const lng = parseInt(await variables.getValue('lng'));
    const zoom = parseInt(await variables.getValue('zoom'));
      try{
        async function initMap() {
          
          // Request needed libraries.
          const { Map } = await google.maps.importLibrary("maps");
          const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
          const map = new Map(document.getElementById("map"), {
            center: { lat, lng },
            zoom,
            mapId: "4504f8b37365c3d0",
          });
          const marker = new AdvancedMarkerElement({
            map,
            position: { lat, lng },
          });
          view.hideLoading();
        }
        
        initMap();
      }catch(e){
        console.error(e);
        view.hideLoading();
      }
    });`,
  css: ` 
  /* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 */
#map {
  height: 100%;
}

/* 
 * Optional: Makes the sample page fill the window. 
 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}`,
  variables: [
    {
      name: 'lat',
      type: 'number',
      defaultFieldValue: -34.397,
      description: 'Latitude'
    },
    {
      name: 'lng',
      type: 'number',
      defaultFieldValue: 150.644,
      description: 'Longitude'
    },
    {
      name: 'zoom',
      type: 'number',
      defaultFieldValue: 8,
      description: 'Zoom'
    }
  ]
};
