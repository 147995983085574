import { createContext } from 'react';
import { Permissions, UserInfo } from '../services/acl.service';

export const CSPContext = createContext({});
export const UserAclProvider = createContext<{
  userInfo: UserInfo;
  permission: Permissions;
  userInfoApiError: Error;
}>({
  permission: Permissions.editor,
  userInfo: {
    acl: { enabled: false, aclWeight: 0, permission: Permissions.viewer },
    user: null
  },
  userInfoApiError: null
});
