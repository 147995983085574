import { env } from '../../../env';
import { IDataStorage } from '../ifs/idata.storage';
import { MondayItemApi } from '../services/monday.item.api';
import { MondayRuntime } from '../services/monday.runtime';

const runtime = MondayRuntime.instance();
const monday = runtime.getMonday();

export class TableStorage implements IDataStorage {
  protected tableKey: string;
  constructor(protected tableName: string) {
    if (!env.MONDAY_APP_ID) throw new Error('MONDAY_APP_ID is not defined in env.ts');
    if (!tableName) throw new Error('tableName is not defined in TableStorage constructor');
    if (tableName.match(/[^a-zA-Z0-9_]/))
      throw new Error('tableName can only contain alphanumeric characters and underscores');
    this.tableKey = runtime.generateKey(`Table:${tableName}`);
  }

  async loadTableData(): Promise<Record<string, Record<string, any>>> {
    try {
      const data = await runtime.getItem(this.tableKey);
      if (typeof data === 'string') return {};
      return data || {};
    } catch (e) {
      console.error('Error while loading table data ', e, this.tableKey);
      throw new Error('Error while loading table data' + this.tableKey);
    }
    return {};
  }
  saveTableData(tableData: Record<string, Record<string, any>>) {
    return runtime.setItem(this.tableKey, tableData);
  }
  async upsert(record: Record<string, any>) {
    const tableData = await this.loadTableData();
    tableData[record.id] = record;
    if (!record.createdAt) {
      Object.assign(record, {
        createdAt: new Date().getTime(),
        createdById: runtime.getUserId()
      });
    }
    Object.assign(record, {
      updatedAt: new Date().getTime(),
      updatedById: runtime.getUserId()
    });
    await this.saveTableData(tableData);
    return record;
  }
  async insert(record: Record<string, any>) {
    const tableData = await this.loadTableData();
    if (record.id) {
      const existing = tableData[record.id];
      if (!existing) {
        throw new Error(
          'Record with id ' + existing.id + ' already exists in table ' + this.tableName
        );
      }
    } else {
      record.id = crypto.randomUUID();
    }
    Object.assign(record, {
      createdAt: new Date().getTime(),
      createdById: runtime.getUserId()
    });
    tableData[record.id] = record;
    await this.saveTableData(tableData);
    return record;
  }
  async update(record: Record<string, any>) {
    if (!record.id) throw new Error('Record id is not defined');
    const tableData = await this.loadTableData();
    const existing = tableData[record.id];
    if (!existing) {
      throw new Error('Record with id ' + record.id + ' does not exist in table ' + this.tableName);
    }
    Object.assign(record, {
      updatedAt: new Date().getTime(),
      updatedById: runtime.getUserId()
    });
    tableData[record.id] = record;
    await this.saveTableData(tableData);
    return record;
  }
  async delete(recordId: string) {
    const tableData = await this.loadTableData();
    const existing = tableData[recordId];
    if (!existing) {
      throw new Error('Record with id ' + recordId + ' does not exist in table ' + this.tableName);
    }
    delete tableData[recordId];
    await this.saveTableData(tableData);
  }
  async findById(recordId: string) {
    const tableData = await this.loadTableData();
    return tableData[recordId];
  }
  async findByField(fieldName: string, fieldValue: any) {
    const tableData = await this.findAll();
    return tableData.find((r) => r[fieldName] === fieldValue);
  }
  async findAllByField(fieldName: string, fieldValue: any) {
    const tableData = await this.findAll();
    return tableData.filter((r) => r[fieldName] === fieldValue);
  }
  async findAll(query?: any): Promise<any[]> {
    return Object.values(await this.loadTableData());
  }
}
