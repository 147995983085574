import { env } from '../../../env';

const boardViewContext = {
  theme: 'light', // or "dark"
  account: {
    id: '5' // unique account ID
  },
  subscription: {
    is_trial: false,
    billing_period: null,
    plan_id: 'FreePlan',
    days_left: 1,
    renewal_date: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 31).toISOString()
  },
  user: Object.assign(
    {
      id: '2631211', // unique user ID
      isAdmin: false, // or true
      isGuest: false, // or true
      isViewOnly: false, // or true
      countryCode: 'IL', // user's two-letter country code
      currentLanguage: 'en', // user's default language
      timeFormat: '12H', // or "24H"
      timeZoneOffset: 3 // time zone offset based on GMT
    },
    env.MOCK_CONTEXT_OVERRIDE?.user
  ),
  region: 'use1', // availability zone ID
  app: {
    id: 10000205, // unique app ID
    clientId: '120372d8b9047cb85966f070ca0ee490' // unique client ID
  },
  appVersion: {
    id: 10000345, // unique app version ID
    name: 'Shay Test App API Context - New App', // name of the app's current version
    status: 'draft', // or "live"
    type: 'major', // or "minor"
    versionData: {
      major: 1, // version number
      minor: 0, // version number
      patch: 0, // patch number
      type: 'major' // or "minor"
    }
  },
  boardId: 118405813, // unique board ID
  boardIds: [
    118405813 // list of connected boards
  ],
  boardViewId: 10887, // unique board view ID
  viewMode: 'fullScreen', // or "split" or "widget"
  instanceId: 10887, // unique instance ID for the feature on the board
  instanceType: 'board_view', // feature type
  workspaceId: 7870 // unique workspace ID
};
export const MockContextData = {
  boardView: boardViewContext,
  itemView: {
    ...boardViewContext,
    itemId: 64564519872, // unique item ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'item_view' // feature type
  },
  dashboard: {
    ...boardViewContext,
    dashboardId: 64564519872, // unique dashboard ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'dashboard' // feature type
  },
  notification: {
    ...boardViewContext,
    notificationId: 64564519872, // unique notification ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'notification' // feature type
  },
  workspace: {
    ...boardViewContext,
    workspaceId: 64564519872, // unique workspace ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'workspace' // feature type
  },
  account: {
    ...boardViewContext,
    accountId: 64564519872, // unique account ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'account' // feature type
  },
  user: {
    ...boardViewContext,
    userId: 64564519872, // unique user ID
    instanceId: 65461164, // unique instance ID for the feature on the board
    instanceType: 'user' // feature type
  },
  document: {
    ...boardViewContext,
    docId: 4048455,
    objectId: 3877057000,
    instanceType: 'doc_app_feature',
    workspaceId: 668837,
    focusedBlocks: [
      {
        id: '5fdcd890-ec0e-4ba2-bc63-1fb93678f31c',
        createdUserId: 4322888,
        accountId: 256337,
        docId: 4048462,
        type: 'normal text',
        content: {
          alignment: 'left',
          direction: 'ltr',
          deltaFormat: [
            {
              insert: 'THIS TEXT IS SELECTED'
            }
          ],
          base64Encoded:
            'CgWSzev3CwCBwZ3R4QMWBoGSzev3CwUJgZLN6/cLDgjBks3r9wsOks3r9wsPBcGSzev3CxuSzev3Cw8EAoTcqtULAMHBndHhAxaSzev3CwAFwZLN6/cLBZLN6/cLBgQCkPzpyAoAwZLN6/cLFs7BlMsJAAXBzsGUywkFzsGUywkGBALOwZTLCQCBks3r9wsWBoHOwZTLCQUEA8Gq/b8JAAEBCXRleHRCbG9ja40BgcGq/b8JjAFBwcGq/b8JjAHBqv2/CY0BAgKlh5KWBQDEzsGUywkJsv/dhAIABUhFTExPxLL/3YQCBbL/3YQCBgZSQUNIRUwFwIKHiQQAwcGq/b8JzQHBndHhAw0CwcGd0eEDD8Gd0eEDEAHBwZ3R4QMRwZ3R4QMSAcHBndHhAxPBndHhAxQBwcGd0eEDFcGd0eEDFgEJwZ3R4QMAgcGq/b8JzQEIwcGq/b8JzQHBndHhAwABgcGd0eEDBwTBwar9vwnNAcGd0eEDCAGBwZ3R4QMMAoHBndHhAw8CgcGd0eEDEQKBwZ3R4QMTAoHBndHhAxUBApipqrEDAMGSzev3Cw6Szev3CxcEwZLN6/cLG5LN6/cLHAQDsv/dhAIAgc7BlMsJCQWEsv/dhAIEASCBsv/dhAIFBgnBqv2/CQEA0AHBndHhAwEAF5LN6/cLAQAghNyq1QsBAAnOwZTLCQEACpD86cgKAQAJwIKHiQQBAAaYqaqxAwEACLL/3YQCAgAFBgY='
        },
        position: 6529024,
        parentBlockId: null,
        createdAt: '2023-02-13T20:09:34.000Z',
        updatedAt: '2023-02-13T20:09:55.000Z'
      }
    ],
    placement: 'contextualToolbar',
    highlightedText: 'THIS TEXT IS SELECTED',
    range: {
      index: 0,
      length: 21
    },
    blockId: '5fdcd890-ec0e-4ba2-be83-1fb93678f66f'
  }
};
