import { indexOf } from 'lodash';
import { BeanManager } from '../../system/services/system/bean.manager';
import { PermissionService } from './csp.service';
import { MondayRuntime } from '../../monday/services/monday.runtime';

class HtmlService {
  empty(dom) {
    if (!dom) {
      return this;
    }
    while (dom.firstElementChild) {
      dom.firstElementChild.remove();
    }
    return this;
  }
  appendIframe(doc, dom, id = 'frame-renderer') {
    const iframe = doc.createElement('iframe');
    iframe.class = 'preview-window';
    iframe.src = 'about:blank#sandbox';
    iframe.frameBorder = '0';
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.position = 'relative';
    iframe.id = id;
    //iframe.sandbox =
    // eslint-disable-next-line max-len
    // 'allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-top-navigation allow-downloads allow-presentations allow-orientation-lock allow-pointer-lock allow-popups-to-escape-sandbox allow-presentation';
    dom.appendChild(iframe);
    return iframe;
  }
  interceptListeners(dom) {
    if (!dom.listener) {
      dom.listener = {};
    }
    const interceptedListener = dom.addEventListener;
    dom.addEventListener = function (eventName, callback) {
      if (!dom.listener[eventName]) {
        dom.listener[eventName] = [];
      }
      dom.listener[eventName].push(callback);
      return interceptedListener.apply(this, arguments);
    };
  }
  /**
   * @param {Element} dom
   * @returns {this}
   */
  removeAllListener(dom) {
    // @ts-ignore
    const eventListeners = dom.listener;
    if (eventListeners) {
      try {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        for (const eventName in eventListeners) {
          const listeners = eventListeners[eventName];
          for (const listener of listeners) {
            dom.removeEventListener(eventName, listener);
          }
        }
      } catch (e) {
        console.error('Error while removing listeners', e);
      }
    }
    return this;
  }
  appenHtmlToIframe(
    iframe: any,
    {
      html,
      javascript,
      css,
      csps = {},
      context = {},
      bodyStyle = {}
    }: {
      html: string;
      javascript?: string;
      css?: string;
      csps?: any;
      context?: any;
      bodyStyle?: any;
    }
  ) {
    const pageContent = this.toHtmlContentString(html, {
      javascript,
      css,
      csps,
      context,
      bodyStyle
    });
    // iframe.src = `data:text/html;charset=utf-8,${encodeURI(pageContent)}`;
    iframe.srcdoc = pageContent;
    return this;
  }
  /**
   * @param {Document} doc
   * @param {string} html
   * @returns {Promise<this>}
   */
  async appendHtmlUnsafe(
    doc,
    {
      html,
      javascript,
      css,
      csps = {}
    }: { html: string; javascript?: string; css?: string; csps?: any }
  ) {
    // const regex = new RegExp('<script.*src="(.*)"(\\s|>)?.*?></script>', 'g');
    // const matchedRegex = Array.from(html.matchAll(regex));
    // for (const result of matchedRegex) {
    //   if (result.length > 1) {
    //     try {
    //       await this.appendRemoteScript(doc, result[1]);
    //     } catch (e) {
    //       doc.defaultView?.console.error(e);
    //     }
    //   }
    // }
    // const unsafeHtml = doc.createRange().createContextualFragment(html);
    // doc.body.append(unsafeHtml);
    html = this.toHtmlContentString(html, { javascript, css, csps });
    doc.open();
    doc.write(html);
    doc.close();
    return this;
  }
  async appendRemoteScript(doc, src) {
    return new Promise((resolve, reject) => {
      const script = doc.createElement('script');
      script.onload = function () {
        resolve(src);
      };
      script.onerror = (e) => {
        reject('unable to load script ' + src);
      };
      script.src = src;
      doc.head.appendChild(script);
    });
  }
  /**
   * @param {Document} doc
   * @param {string} javascript
   * @returns {this}
   */
  appendScript(doc, javascript) {
    const script = doc.createElement('script');
    script.innerHTML = `(()=>{\n
    ${javascript}
    })()`;
    // script.async = true;
    doc.head.appendChild(script);
    return this;
  }
  /**
   * @param {Document} doc
   * @param {string} css
   */
  appendStyle(doc, css) {
    const style = doc.createElement('style');
    style.innerHTML = `\n
    ${css}
    `;
    doc.head.appendChild(style);
    return this;
  }
  toHtmlContentString(html, { javascript, css, csps, context = {}, bodyStyle = {} }) {
    let cspContent = '';
    let cspMetaTag = '';
    let javascriptTag = '';
    if (javascript) {
      javascriptTag = `<script> 
      ${javascript}      
      </script>`;
    }

    if (csps.enableCSP === true) {
      const mappedPolicies = PermissionService.instance().cspListToMap(csps.policies);
      cspContent = Object.keys(mappedPolicies)
        .map((key) => `${key} ${mappedPolicies[key].join(' ')}`)
        .join('; ');
      cspMetaTag = `<meta http-equiv="Content-Security-Policy" content="${cspContent}">`;
    }
    html = `<html>
      <head> 
        ${cspMetaTag}
        <script>
          window.$__context__ = ${JSON.stringify(context)}
        </script>
        <script 
          src="${MondayRuntime.instance().getAssetURL('/assets/iframe.window.helper.js')}"
        ></script>
        <style name="body-style">
          html,body {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
          }
          body{
            ${Object.keys(bodyStyle)
              .map((key) => `${key}: ${bodyStyle[key]};`)
              .join('\n')}
          }
        </style>
        <style>
          ${css}
        </style>
      </head>
      ${html}
      ${javascriptTag}
      
      </html>`;
    // below code doesnt work if dom elements is outside of <html> tag
    // if (html.indexOf('<html') === -1) {
    // } else if (html.indexOf('<head>') === -1) {
    //   const index = html.indexOf('<html');
    //   const htmlCloseTag = html.indexOf('>', index);
    //   html = `${html.substring(0, htmlCloseTag + 1)}
    //   <head>
    //     <meta http-equiv="Content-Security-Policy" content="${cspContent}">
    //   </head>
    //   ${html.substring(htmlCloseTag + 1)}`;
    // } else {
    //   html = html.replace(/<head>([\s\S]*?)<\/head>/, function (a, b) {
    //     return `<head>
    //   <meta http-equiv="Content-Security-Policy" content="${cspContent}">
    //   ${b}
    //   </head>`;
    //   });
    // }
    return html;
  }
  appendCSP(doc, csps) {
    const meta = doc.createElement('meta');
    meta.httpEquiv = 'Content-Security-Policy';
    meta.content = Object.keys(csps)
      .map((key) => `${key} ${csps[key].join(' ')}`)
      .join('; ');
    doc.head.appendChild(meta);
    return this;
  }
  dispatchDocEvent(doc, eventName = 'DOMContentLoaded') {
    doc.dispatchEvent(
      new Event(eventName, {
        bubbles: true,
        cancelable: true
      })
    );
    return this;
  }
  getFullPageHeight(doc) {
    const body = doc.body;
    const html = doc.documentElement;
    return doc.body.offsetHeight + 20;
    // return Math.max(
    //   body.scrollHeight,
    //   body.offsetHeight,
    //   html.clientHeight,
    //   html.scrollHeight,
    //   html.offsetHeight
    // );
  }
}
export default BeanManager.register(HtmlService);
