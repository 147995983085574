import { BeanManager } from '../../system/services/system/bean.manager';
import mondaySdk from 'monday-sdk-js';
import { MondayExecuteCommands, MondayMock } from '../mock/monday.mock';
import { env } from '../../../env';
import { ReflectionUtil } from '../../system/utils/reflection.util';
import { ITemplateConfig } from '../../advance-html/model/itemplate.config';
const monday = mondaySdk();
export enum StorageLevel {
  GLOBAL = 'global',
  INSTANCE = 'instance'
}
export interface MondayJWT {
  slug: string;
  client_id: string;
  user_id: string;
  account_id: string;
  app_id: string;
  app_version_id: string;
  instance_id: string;
  is_admin: boolean;
  is_guest: boolean;
  is_view_only: boolean;
  user_kind: 'admin' | 'guest' | 'view_only';
}
export interface FeatureModalProps {
  url?: string;
  urlPath?: string;
  urlParams?: Record<string, any>;
  height?: number | string;
  width?: number | string;
}
export const MondayRuntime = BeanManager.register(
  class MondayRuntime {
    protected parsedJwt: { dat: MondayJWT; exp: number };
    constructor() {
      this.parseJWTFromURL();
    }
    getMonday() {
      if (!this.isRunningInMonday()) {
        return MondayMock.instance();
      }
      //@ts-ignore
      return (window.monday = monday);
    }
    parseJWTFromURL() {
      if (!this.isRunningInMonday()) {
        //@ts-ignore
        this.parsedJwt = { dat: {}, exp: 12334 };
        return;
      }
      const href = window.location.href;
      const queryParams = href.split('?');
      if (queryParams.length > 1) {
        const params = new URLSearchParams(queryParams[queryParams.length - 1]);
        const token = params.get('sessionToken');
        if (token) {
          this.parsedJwt = ReflectionUtil.parseJWT(token);
        }
      }
    }
    getBaseUrl(): string {
      return window.location.origin;
    }
    getFullPageUrl(pagePath: string): string {
      return `${this.getBaseUrl()}/#${pagePath}`;
    }
    listen(event: string, callback: (data: any) => void) {
      //@ts-ignore
      this.getMonday().listen(event, callback);
    }
    sendValueCreatedForUser() {
      try {
        return this.getMonday().execute('valueCreatedForUser');
      } catch (e) {
        console.error('error while value created', e);
      }
    }
    execute(command: MondayExecuteCommands, params?: any) {
      return this.getMonday().execute(command, params);
    }
    openFeatureModal(params: FeatureModalProps = {}) {
      if (params.urlPath) {
        params.url = this.getFullPageUrl(params.urlPath);
        delete params.urlPath;
      }
      return this.getMonday().execute('openAppFeatureModal', params);
    }
    async closeFeatureModal() {
      await this.getMonday().execute('closeAppFeatureModal');
    }
    protected cache = new Map<string, any>();
    async saveSettings(settings: any) {
      await this.getMonday().set('settings', settings);
      this.cache.set('settings', settings);
    }
    async getSettings(cached = false) {
      if (cached && this.cache.has('settings')) {
        return this.cache.get('settings');
      }
      const result = await this.getMonday().get('settings');
      const settings = result.data?.value || { templateConfig: {} };
      this.cache.set('settings', settings);
      return settings;
    }
    getUserId() {
      return 'admin';
    }
    isRenderingInModal() {
      return false;
    }

    async getContext() {
      const result = await this.getMonday().get('context');
      return result.data;
    }
    async getContextUser() {
      const result = await this.getMonday().get('context');
      return result.data?.user;
    }
    getParsedToken() {
      return this.parsedJwt;
    }
    async loadTargetContext() {
      return await this.getContext();
    }
    getModalRoute() {
      return null;
    }
    getAssetURL(assetPath: string) {
      return assetPath;
    }
    getUser() {
      return null;
    }
    getAppId(): string {
      return env.MONDAY_APP_ID;
    }
    generateKey(key: string) {
      return `${this.getAppId()}:${key}`;
    }
    async getConfig() {
      return await this.getContext();
    }
    updateUIStyle(config: ITemplateConfig) {
      document.body.style.setProperty('overflow', 'hidden');
      // if (config.styleSettings) {
      //   for (let key in config.styleSettings) {
      //     document.body.style.setProperty(key, config.styleSettings[key]);
      //   }
      // }
      if (config.enableFullScreen) {
        document.body.classList.remove(`disable-full-screen`);
        document.body.classList.add(`enable-full-screen`);
      } else {
        document.body.classList.remove(`enable-full-screen`);
        document.body.classList.add(`disable-full-screen`);
      }
    }
    showFullBody() {}
    isRenderringAsMacro() {
      return false;
    }
    getRouter() {
      return {
        open: (url: string) => {
          if (this.isRunningInMonday()) {
            monday.execute('openLinkInTab', { url });
          } else {
            window.open(url, '_blank');
          }
        }
      };
    }
    isRunningInMonday() {
      return window.parent !== window;
    }

    adjustHeightBasedOnRootDiv() {}
    parseResponse(result) {
      if (!result) return null;
      if (!result.data.success) {
        throw new Error('Error response from monday: ' + JSON.stringify(result));
      }
      return result.data.value;
    }
    getStorageInstance(level: StorageLevel = StorageLevel.GLOBAL) {
      if (level === StorageLevel.INSTANCE) {
        return this.getMonday().storage.instance;
      }
      return this.getMonday().storage;
    }
    async deleteItem(key: string, level = StorageLevel.GLOBAL) {
      if (!key) throw new Error('Key is not defined in deleteItem method');
      const storage = this.getStorageInstance(level);
      const response = await storage.deleteItem(key);
      return this.parseResponse(response);
    }
    async setItem(key: string, value: any, level = StorageLevel.GLOBAL) {
      if (!key) throw new Error('Key is not defined in setItem method');
      if (this.isRunningInMonday()) {
        value = JSON.stringify(value);
      }
      const storage = this.getStorageInstance(level);
      const response = await storage.setItem(key, value);
      return this.parseResponse(response);
    }
    async getItem(tableKey: string, level = StorageLevel.GLOBAL) {
      if (!tableKey) throw new Error('Key is not defined in getItem method');
      const storage = this.getStorageInstance(level);
      if (this.isRunningInMonday()) {
        const response = await storage.getItem(tableKey);
        const parsed = this.parseResponse(response);
        return ReflectionUtil.safeParse(parsed, null);
      }
      const response = await storage.getItem(tableKey);
      return this.parseResponse(response);
    }
  }
);
