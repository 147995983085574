export default {
  name: 'Instagram Embed',
  description: 'Embed an Instagram post on a webpage.',
  category: 'Social Media / Instagram',
  tags: ['instagram', 'social media'],
  active: true,
  icon: 'fa-brands fa-instagram',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe src="about:blank" width="100%" height="100%" frameborder="0" scrolling="yes" allowtransparency="true"></iframe>`,
  script: `/** JavaScript */
  $template.ready(async () => {
    const variables = $template.useVariables();
    let postUrl = await variables.getValue('Instagram_Post_URL');
    postUrl = postUrl.split('?')[0];
    if(postUrl.endsWith('/')){
      postUrl = postUrl.substring(0,postUrl.length-1);
    }
    const iframe = document.getElementsByTagName('iframe')[0];
    const view = $template.useView();
    view.showLoading();
    iframe.onload = () => {
      view.hideLoading();
    };
    iframe.onerror = () => {
      view.hideLoading();
      // view.showAlert('Error loading video', 'danger');
    }
    iframe.src = postUrl+'/embed';
  });`,
  css: ``,
  variables: [
    {
      label: 'Instagram Post URL',
      name: 'Instagram_Post_URL',
      type: 'multilineString',
      defaultFieldValue: 'https://www.instagram.com/p/Cwp7KvYPrBm',
      mandatory: true,
      description: 'Instagram Post URL',
      defaultVariable: true
    }
  ]
};
