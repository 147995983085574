import { Button, Col, Row } from 'antd';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { ReflectionUtil } from '../../../system/utils/reflection.util';
import htmlService from '../../services/html.service';
import { DeleteTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { CodeEditor } from './CodeEditor';
import { useMonaco } from '@monaco-editor/react';
import logTokenizer from './monaco-tokenizer/monaco.log';
import { useDebounce } from '../../../system/hooks/useDebounce';
const levelMapper = { LOG: 'INFO' };
const formatMessgae = (messages) => {
  return messages
    .map((message) => {
      const level = message.level.toUpperCase();
      return `${levelMapper[level] || level}: ${message.args.join(' ')}`;
    })
    .join('\n');
};
export function EditorConsole({ messages, console, onClose, onClear }) {
  const [minimized, setMinimized] = useState(false);
  onClose = onClose || function () {};
  const monaco = useMonaco();
  /**@type {any} */
  const editorRef = useRef(null);
  useEffect(() => {
    if (monaco) {
      // logTokenizer(monaco);
    }
  }, [monaco]);
  useEffect(() => {
    editorRef.current?.getModel().setValue(formatMessgae(messages));
  }, [messages]);

  return (
    <div
      className={`console-warpper ${minimized ? 'minimized' : 'expanded'}`}
      style={{ height: minimized ? '10px' : '200px' }}>
      <div className="console-actions">
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="link"
              shape="round"
              icon={<DeleteTwoTone />}
              size="small"
              onClick={() => {
                onClear();
              }}>
              Clear
            </Button>
            <Button
              type="link"
              shape="round"
              icon={<CloseCircleTwoTone />}
              size="small"
              onClick={() => {
                onClose('minimize');
              }}>
              Close
            </Button>
          </Col>
        </Row>
      </div>
      <div className="console-messages" style={{ display: minimized ? 'none' : 'block' }}>
        <CodeEditor
          value={formatMessgae(messages)}
          defaultLanguage="log"
          onEditorInit={(editor) => {
            editorRef.current = editor;
          }}
          editorOptions={{
            readOnly: true,
            lineNumbers: 'off',
            language: 'log'
          }}
        />
      </div>
    </div>
  );
}
EditorConsole.propTypes = {
  messages: PropTypes.array,
  console: PropTypes.any,
  onClose: PropTypes.func,
  onClear: PropTypes.func
};
