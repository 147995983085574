import React, { Component } from 'react';
import { Card } from 'antd';
import _ from 'lodash';
import { Allotment } from 'allotment';
import { Sandbox } from '../editor/Sandbox';
import IframeRenderer from '../editor/IframeRenderer';
import { CodeEditorIDEMenu } from './CodeEditorIDEMenu';
import { EditorConsole } from '../editor/EditorConsole';
import { FullScreen } from '../../../system/components/fullscreen';
import { ITemplateEntity } from '../../model/itemplate';
import { ThemeService } from '../../../system/services/ui/theme.service';

const themeService = ThemeService.instance();

export interface CodeEditorIDEProps {
  actions: any;
  headerActions: any;
  record: ITemplateEntity;
  onChange: (change, type?) => void;
  onDetailsChange: (change) => void;
  onMenuItemClick: (key) => void;
  readOnly?: boolean;
  templateLoaded: boolean;
  cspSettings: { enableCSP: boolean; policies: any[] };
  hideBackButton: boolean;
}
export interface CodeEditorIDEState {
  menuSettings: any;
  executeCode: number;
  consoleMessages: any[];
  recordLoaded: boolean;
  templateRenderChanged: ITemplateEntity;
}
export class CodeEditorIDE extends Component<CodeEditorIDEProps, CodeEditorIDEState> {
  renderCountAfterLoad = 0;
  codePaneRef: React.RefObject<any>;
  menuRef: React.RefObject<any>;
  value: ITemplateEntity;
  refereshPreviewDebounce = _.debounce(() => {
    this.setState({ templateRenderChanged: this.value });
  }, 500);
  constructor(props: CodeEditorIDEProps) {
    super(props);
    this.codePaneRef = React.createRef();
    this.menuRef = React.createRef<any>();
    this.value = props.record;
    this.state = {
      templateRenderChanged: this.props.record,
      menuSettings: {
        layout: { left: true, right: true, bottom: false },
        autoExecute: true,
        hideBackButton: props.hideBackButton
      },
      executeCode: 0,
      consoleMessages: [],
      recordLoaded: false
    };
  }
  setLayout(layout: { bottom?: boolean; left?: boolean; right?: boolean }) {
    this.setState((prevState) => {
      prevState.menuSettings.layout = { ...prevState.menuSettings.layout, ...layout };
      return prevState;
    });
  }
  setMenuSettings(settings) {
    this.setState((prevState) => ({ menuSettings: { ...prevState.menuSettings, ...settings } }));
  }
  addConsoleMessage(message) {
    this.setState((prevState) => ({
      ...prevState,
      consoleMessages: [...prevState.consoleMessages, message]
    }));
  }
  setConsoleMessages(messages) {
    this.setState((prevState) => ({ ...prevState, consoleMessages: messages }));
  }
  setExecuteCode(executeCode) {
    this.setState((prevState) => ({ ...prevState, executeCode: prevState.executeCode + 1 }));
  }
  setvalue(newValue) {
    this.value = newValue;
    this.refereshPreviewDebounce();
  }

  getValue() {
    return this.value;
  }

  openPropDialog(display = true) {
    if (this.menuRef.current) {
      this.menuRef.current.openInfoModal(display);
    }
  }
  render() {
    if (this.props.templateLoaded) {
      this.renderCountAfterLoad++;
    }
    if (!this.props.templateLoaded || this.renderCountAfterLoad < 2) {
      this.setvalue(this.props.record);
    }
    const { actions, headerActions, record, readOnly } = this.props;
    const { menuSettings, executeCode, consoleMessages } = this.state;
    return (
      <div className="code-editor-ide-warpper">
        <div className="ide-menue">
          <CodeEditorIDEMenu
            ref={this.menuRef}
            record={record}
            settings={menuSettings}
            actions={actions}
            headerActions={headerActions}
            onMenuItemClick={(key) => {
              if (key === 'execute') {
                this.setExecuteCode(true);
              }
              this.props.onMenuItemClick(key);
            }}
            onDetailsChange={(changedRecord) => {
              const updatedvalue = { ...this.value, ...changedRecord };
              this.setvalue(updatedvalue);
              this.props.onDetailsChange(updatedvalue);
              this.props.onChange(updatedvalue);
            }}
            onSettingChange={(changedSettings) => {
              this.setState((prevState) => ({
                menuSettings: { ...prevState.menuSettings, ...changedSettings }
              }));
            }}
          />
        </div>
        <div className="ide-body">
          <Allotment defaultSizes={[300, 200]}>
            <Allotment.Pane>
              <Allotment ref={this.codePaneRef} vertical={true} defaultSizes={[100, 50]}>
                <Allotment.Pane>
                  <Sandbox
                    shouldRender={!this.props.templateLoaded || this.renderCountAfterLoad < 2}
                    readOnly={readOnly}
                    config={{ height: '100%' }}
                    css={record.css}
                    javascript={record.javascript}
                    html={record.html}
                    variables={record.variables}
                    onChange={(sandBoxRecord, type) => {
                      if (sandBoxRecord[type] !== this.value[type]) {
                        const updatedvalue = { ...this.value, [type]: sandBoxRecord[type] };
                        this.setvalue(updatedvalue);
                        this.props.onChange(updatedvalue);
                      }
                    }}></Sandbox>
                </Allotment.Pane>
                <Allotment.Pane visible={menuSettings.layout.bottom}>
                  <div className="console">
                    <EditorConsole
                      messages={consoleMessages}
                      onClose={(action) => {
                        this.setLayout({ bottom: false });
                      }}
                      onClear={() => this.setConsoleMessages([])}></EditorConsole>
                  </div>
                </Allotment.Pane>
              </Allotment>
            </Allotment.Pane>
            <Allotment.Pane visible={menuSettings.layout.right}>
              <FullScreen>
                <Card title="Live Preview" className="preview-card">
                  <IframeRenderer
                    debounce={1000}
                    template={this.state.templateRenderChanged}
                    autoExecute={menuSettings.autoExecute}
                    execute={executeCode}
                    cspSettings={this.props.cspSettings}
                    onExecute={() => {
                      //  this.setExecuteCode(false);
                    }}
                    bodyStyle={{
                      backgroundColor: themeService.getTheme().token?.backgroundColor,
                      color: themeService.getTheme().token?.color
                    }}
                    onConsoleMessage={(message) => {
                      // @ts-ignore
                      this.addConsoleMessage(message);
                    }}
                  />
                </Card>
              </FullScreen>
            </Allotment.Pane>
          </Allotment>
        </div>
      </div>
    );
  }
}
