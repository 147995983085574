import Editor, { useMonaco } from '@monaco-editor/react';
import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IGNORE_MONACO_THEMES,
  ThemeEventBus,
  ThemeService
} from '../../../system/services/ui/theme.service';
import { useAsyncEffect } from '../../../system/hooks/use.async.effect';
const themeService = ThemeService.instance();
export const defauleEditorOptions = {
  minimap: {
    enabled: false
  },
  automaticLayout: true,
  fontSize: 14,
  fontFamily: 'monospace',
  lineNumbers: 'on',
  lineNumbersMinChars: 3,
  scrollBeyondLastLine: false,
  wordWrap: 'on',
  wordWrapColumn: 80,
  wrappingIndent: 'indent',
  wordWrapMinified: true,
  renderLineHighlight: 'none',
  renderIndentGuides: false,
  renderLineHighlightOnlyWhenFocus: true,
  renderValidationDecorations: 'on',
  overviewRulerBorder: false,
  hideCursorInOverviewRuler: true,
  overviewRulerLanes: 0,
  cursorStyle: 'line',
  cursorWidth: 2,
  cursorSurroundingLines: 0,
  cursorSurroundingLinesStyle: 'default',
  cursorHeight: 1
};
export interface CodeEditorProps {
  value: string;
  defaultLanguage: string;
  height?: any;
  onChange?: (value: string, editor: any) => void;
  editorOptions?: any;
  onEditorInit?: (editorRef) => void;
  style?: React.CSSProperties;
}
export const CodeEditor = forwardRef<any, CodeEditorProps>(
  ({
    defaultLanguage,
    height = '100%',
    value,
    onChange,
    editorOptions,
    onEditorInit,
    style = {}
  }: CodeEditorProps) => {
    onEditorInit = onEditorInit || function () {};
    /**@type {any} */
    const monaco = useMonaco();
    const editorRef = useRef(null);
    const monacoRef = useRef(null);
    const monacoThemeName = themeService.getThemeName();
    const initialized = useRef(false);
    const updateTheme = async () => {
      if (monaco && editorRef.current) {
        monaco.editor.setTheme(monacoThemeName);
      }
    };
    // updateTheme();
    useEffect(() => {
      ThemeEventBus.on('theme.switch', (themeName) => {
        updateTheme();
      });
    }, []);

    useEffect(() => {
      if (editorRef.current) {
        const monacoInstance = editorRef.current;
        if (!initialized.current) {
          onEditorInit(monacoInstance);
          initialized.current = true;
          if (monaco) {
            themeService.defineMonacoThemes(monaco.editor);
          }
          updateTheme();
        }
      }
    }, [editorRef.current]);
    useEffect(() => {
      if (editorRef.current && editorOptions) {
        const monacoInstance = editorRef.current;
        monacoInstance.updateOptions({ ...editorOptions });
      }
    }, [editorRef.current, editorOptions]);

    return (
      <div className="code-editor-wrapper" style={style}>
        <div className="editor">
          <Editor
            defaultLanguage={defaultLanguage}
            value={value?.toString() || ''}
            height={height}
            onMount={(editor, monaco) => {
              editorRef.current = editor;
              monacoRef.current = monaco;
            }}
            onChange={(changedValue) => {
              if (changedValue !== value) {
                onChange && onChange(changedValue, editorRef.current);
              }
            }}
            theme={monacoThemeName}
          />
        </div>
      </div>
    );
  }
);
CodeEditor.displayName = 'CodeEditor';
