import { BeanManager } from '../../system/services/system/bean.manager';
import { TableNames } from '../model/table.names';

export enum Permissions {
  viewer = 'viewer',
  editor = 'editor',
  admin = 'admin'
}
export const PermissionHierarchy = [Permissions.viewer, Permissions.editor, Permissions.admin];
export interface UserInfo {
  acl: UserAcl;
  user: any;
}
export interface UserAcl {
  enabled: boolean;
  aclWeight: number;
  permission: Permissions;
}
export interface Acl {
  entityId: string;
  entityDisplayName?: string;
  permission: Permissions;
}
export interface AppAclConfig {
  enabled: boolean;
  userAcls: Acl[];
  groupAcls: Acl[];
}
export const ACL_STORAGE_KEY = 'app.acl.config';
export const AclService = BeanManager.register(
  class AclService {
    protected userInfo: UserInfo;
    loading: boolean = false;
    isLoading(): boolean {
      return this.loading;
    }
    isInitialized(): boolean {
      return !!this.userInfo;
    }
    canEdit(): boolean {
      return this.isInitialized() && (this.isEditor() || this.isAdmin());
    }
    async getUserInfo(): Promise<UserInfo> {
      if (this.userInfo) {
        return this.userInfo;
      }
      this.userInfo = await this.loadUserInfo();
      return this.userInfo;
    }
    async loadUserInfo(): Promise<UserInfo> {
      return {
        acl: {
          enabled: false,
          aclWeight: 0,
          permission: Permissions.admin
        },
        user: {
          operations: []
        }
      };
    }
    async saveAclConfig(userPermissionConfig: AppAclConfig) {}
    async getACLConfig(): Promise<AppAclConfig> {
      return {
        enabled: false,
        userAcls: [],
        groupAcls: []
      };
    }
    isAclEnabled() {
      return this.userInfo.acl.enabled;
    }
    isAdmin() {
      if (this.isAclEnabled() === false) {
        return true;
      }
      const operations = this.userInfo.user.operations;
      if (
        operations &&
        operations.length > 0 &&
        operations.find((o) => o.operation === 'administer')
      ) {
        return true;
      }
      return this.userInfo.acl.permission === Permissions.admin;
    }
    isViewer() {
      return this.isAdmin() === false && this.isEditor() === false;
    }
    isEditor() {
      return this.userInfo.acl.permission === Permissions.editor;
    }
    getPermission(): Permissions {
      if (this.isAdmin()) {
        return Permissions.admin;
      }
      if (this.isEditor()) {
        return Permissions.editor;
      }
      return Permissions.viewer;
    }
    async hasPermission(permissionWeight: number) {
      await this.loadUserInfo();
      return this.userInfo.acl.aclWeight >= permissionWeight;
    }
  }
);
