import { PanpalEventRegistry } from '../../system/services/messaging/panpal.event.handler';
import { PanpalParentService } from '../../system/services/messaging/panpal.parent.service';
import { BoardEventHandler } from './board.event.handler';
import { ItemEventHandler } from './item.event.handler';
import { TemplateContextEventHandler } from './template.context.event.handler';
import { VariableEventHandler } from './variable.event.handler';
import { TemplateViewEventHandler } from './view.event.handler';

const panpalRegistry = PanpalEventRegistry.instance();

export const RegisterPanpalHandlers = (context: any) => {
  panpalRegistry
    .cleanup()
    .register(
      new VariableEventHandler(context),
      new TemplateContextEventHandler(context),
      new TemplateViewEventHandler(context),
      new ItemEventHandler(context),
      new BoardEventHandler(context)
    );
  // last statement
};
export const ConnectToIframe = async (iframeDOM: HTMLElement) => {
  return await PanpalParentService.instance().connect(iframeDOM);
};
