import { MondayRuntime } from '../../monday/services/monday.runtime';
import { PanpalEventHandler } from '../../system/services/messaging/panpal.event.handler';
const runtime = MondayRuntime.instance();
export class TemplateContextEventHandler implements PanpalEventHandler {
  constructor(private context: any) {}
  sortOrder: number;
  getEventNames(): string[] {
    return [
      'config.get',
      'config.getHeight',
      'context.get',
      'context.getItemId',
      'context.getBoardId'
    ];
  }
  async handle(event: string, payload: any): Promise<any> {
    switch (event) {
      case 'config.get':
        return await runtime.getConfig();
      case 'config.getHeight':
        return (await runtime.getConfig()).height;
      case 'context.get':
        const context = await runtime.getContext();
        delete context.region;
        delete context.viewType;
        delete context.appVersion;
        delete context.appFeature;
        delete context.subscription;
      case 'context.getItemId':
        return (await runtime.getContext()).itemId;
      case 'context.getBoardId':
        return (await runtime.getContext()).boardId;
      default:
        return new Error('No handler found for event ' + event);
    }
  }
}
