import { Button, Result } from 'antd';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useRouteError } from 'react-router-dom';
import { ReactIf } from './modules/system/components/conditional/r.if';
import { ResultStatusType } from 'antd/es/result';
import { MondayRuntime } from './modules/monday/services/monday.runtime';
import { MondayContextService } from './modules/monday/services/monday.context.service';
export interface ErrorType {
  title?: string;
  status?: ResultStatusType;
  message?: string;
  [key: string]: any; // for any other custom properties
}

export interface ErrorPageProps {
  error?: ErrorType;
  showBackButton?: boolean;
}
export default function ErrorPage(props?: ErrorPageProps) {
  const location = useLocation();
  const [showBackButton, setShowBackButton] = React.useState(true);
  let error: ErrorType = useRouteError() || props?.error;
  if (error) {
    console.error('Error catched in errorpage ', error);
  }
  if (!error) {
    if (location.state) {
      if (location.state.error) {
        error = location.state.error;
      } else {
        error = { status: '404', message: 'Not found' };
      }
      if (location.state.showBackButton === false) {
        setShowBackButton(false);
      }
    }
  }
  if (error.response) {
    error = error.response;
  }
  useEffect(() => {
    if (typeof props?.showBackButton !== 'undefined') {
      setShowBackButton(props?.showBackButton);
    }
  }, [props.showBackButton]);
  useEffect(() => {
    (async () => {
      if (await MondayRuntime.instance().isRenderringAsMacro()) {
        setShowBackButton(false);
      }
    })();
  });
  const navigate = useNavigate();

  return (
    <div id="error-page">
      <Result
        status={error.status || '404'}
        title={error.title || '404'}
        subTitle={error.message || 'Sorry, the page you visited does not exist.'}
        extra={
          <ReactIf condition={showBackButton}>
            <Button
              type="primary"
              onClick={async () => {
                const view = await MondayContextService.instance().getViewObject();
                if (view?.instanceType !== 'account_settings_view') {
                  navigate('/macro/render');
                } else {
                  navigate('/admin-page');
                }
              }}>
              Back Home
            </Button>
          </ReactIf>
        }
      />
    </div>
  );
}
