import { Button, Card, Drawer, FormInstance, Tag } from 'antd';
import VariableForm, { Variable } from './variable.form';
import VariableList from './variable.list';
import { useEffect, useRef, useState } from 'react';
import { useAsyncEffect } from '../../../system/hooks/use.async.effect';
import { NotificationService } from '../../../system/services/ui/notification.service';
import { Typography } from 'antd';
import { ReactIf } from '../../../system/components/conditional/r.if';
import { CopyToClipboard } from '../../../system/components/utils/copy.to.clipboard';
import { CodeSnippet } from '../../../system/components/utils/code.snippet';
const { Text, Link } = Typography;
const { Title } = Typography;
export interface VariableCRUDProps {
  variables: Variable[];
  onChange: (variables: Variable[]) => void;
}
export function VarialeCRUD({ variables, onChange }: VariableCRUDProps) {
  const [openUsageDrawer, setOpenUsageDrawer] = useState(false);
  const [variableState, setVariables] = useState<Variable[]>(variables);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const formRef = useRef<FormInstance<Variable>>();
  const updateVariabels = (variables: Variable[]) => {
    setVariables(variables);
    onChange(variables);
  };
  useEffect(() => {
    if (editIndex >= 0) {
      formRef.current.setFieldsValue(variableState[editIndex]);
    } else {
      formRef.current.resetFields();
    }
  }, [editIndex]);
  const javascriptSnippet = `$template.ready(async () => {
    const variables = $template.useVariables(); // get variables service
    ${variableState
      .map((v) => `const ${v.name} = await variables.getValue('${v.name}');`)
      .join('\n    ')}
});`;
  return (
    <div
      className="variable-crud-warpper"
      style={{ padding: '10px', overflow: 'scroll', height: '100%' }}>
      <Drawer
        title="Usage"
        placement="right"
        closable={true}
        open={openUsageDrawer}
        width="800px"
        onClose={() => {
          setOpenUsageDrawer(false);
        }}>
        <Card type="inner" title="Inside Template" style={{ marginTop: '10px' }}>
          The following code snippet demonstrates how to retrieve the value of a variable in the
          JavaScript section of a template. Each variable will return its configured value from
          macro. If no value is configured, the default value will be returned.
          <br />
          <CodeSnippet code={javascriptSnippet} />
        </Card>
      </Drawer>
      <Card
        title={<Title level={5}>Configure variables</Title>}
        extra={
          <div className="extra">
            <Link
              onClick={() => {
                setOpenUsageDrawer(true);
              }}>
              Usage
            </Link>
          </div>
        }>
        <VariableForm
          variable={editIndex < 0 ? undefined : variableState[editIndex]}
          mdode={editIndex < 0 ? 'create' : 'edit'}
          onInit={(form) => {
            formRef.current = form;
          }}
          onSubmit={(variable) => {
            if (variableState.find((v, i) => v.name === variable.name && i !== editIndex)) {
              NotificationService.instance().error({
                message: `Variable name ${variable.name} already exists`
              });
              return;
            }
            if (variableState.find((v, i) => v.defaultVariable === true && i !== editIndex)) {
              NotificationService.instance().error({
                message: 'Only one variable can be default'
              });
              return;
            }
            if (editIndex >= 0) {
              variableState[editIndex] = variable;
            } else {
              variableState.push(variable);
            }
            setEditIndex(-1);
            updateVariabels([...variableState]);
            formRef.current.resetFields();
          }}
          onCancel={() => {
            setEditIndex(-1);
            formRef.current.resetFields();
          }}></VariableForm>
      </Card>
      <div className="variable-list-wrapper">
        <VariableList
          heighlightIndex={editIndex}
          onEdit={(index) => {
            setEditIndex(index);
          }}
          onDelete={(index) => {
            updateVariabels(variableState.filter((_, i) => i !== index));
          }}
          variables={variableState}
        />
      </div>
    </div>
  );
}
