export default {
  id: 'wyswyg',
  name: 'WYSWYG Editor',
  description: `The WYSWYG Editor HTML Template is a pre-built template that allows to edit content on a view with view context . e.g. On item you you will have access to {{item.name}} and on board you will have access to {{board.name}}. You can also access all the variables in the template context.`,
  category: 'Misc / WYSWYG',
  tags: ['wyswyg', 'editor', 'external', 'website', 'Email Template'],
  active: true,
  icon: 'fa-solid fa-map-location-dot',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<!-- HTML -->
  <!-- Include stylesheet -->
  <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
  
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css"
      integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"></script>
  
  <script src="https://cdn.jsdelivr.net/npm/@textea/json-viewer@3"></script>
  <div class="menu-bar row">
      <div class="col-9"></div>
      <div class="col-3"><button style="position:absolute;top:2px;right:10px;" type="button" class="btn btn-primary" data-toggle="modal" data-target="#variableModal">
          Variables
  </button>
      </div>
  </div>
  
  <div class="wrapper">
      <!-- Create the editor container -->
  
      <h3 class="card-title">Editor</h3>
      <div id="editor">
  
  
  
      </div>
      <div class="card preview-card pt-10 mt-10">
          <div class="card-body">
              <h3 class="card-title">Preview</h3>
              <iframe id="preview" frameBorder="0" width="100%" height="50%"></iframe>
          </div>
  
      </div>
      <!-- Button trigger modal -->
  
  
      <!-- Modal -->
      <div class="modal fade" id="variableModal" tabindex="-1" role="dialog" aria-labelledby="variableModal"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Variables</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                  </div>
                  <div class="modal-body">
  
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- Include the Quill library -->
      <script src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js"
          integrity="sha512-WFN04846sdKMIP5LKNphMaWzU7YpMyCU245etK3g/2ARYbPK9Ub18eG+ljU96qKRCWh+quCY7yefSmlkQw1ANQ=="
          crossorigin="anonymous" referrerpolicy="no-referrer"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/handlebars.js/4.7.8/handlebars.min.js"
          integrity="sha512-E1dSFxg+wsfJ4HKjutk/WaCzK7S2wv1POn1RRPGh8ZK+ag9l244Vqxji3r6wgz9YBf6+vhQEYJZpSjqWFPg9gg=="
          crossorigin="anonymous" referrerpolicy="no-referrer"></script>
      <script src="https://cdn.quilljs.com/1.3.6/quill.js"></script>`,
  script: `

  const renderVariables = (context) => {
      const variables = Object.keys(context).map((c) => {
          const cValue = context[c];
          return {
              name: c,
              fields: cValue,
          }
      })
  
      const template = Handlebars.compile(\`
       <div class="variable-doc row">
          {{#each variables}}
         <div class="col-6">
          <h4>{{this.name}}</h4>
          <div class="fields ">
              {{json this.fields}}
          </div>
         </div>
          {{/each}} 
           </div>
      \`);
      const $mbody = $('#variableModal').find('.modal-body');
      // console.log(template({ variables }), variables);
      const renderedTemplate = template({ variables });
  
  
      $mbody.empty().append(renderedTemplate);
      $mbody.find('.fields').each(function () {
          new JsonViewer({
              value: JSON.parse($(this).html()),
          }).render(this);
      })
  }
  /** JavaScript */
  $template.ready(async () => {
      Handlebars.registerHelper('json', function (context) {
          return JSON.stringify(context, null, 4);
      });
      const preview = document.getElementById('preview');
      const view = await $template.useView();
      const config = await $template.useConfig();
      const context = await config.get();
      const itemAPI = await $template.useItemAPI();
      view.showLoading();
      try {
          let currentItem = {};
          let currentBoard = {};
          if (context.boardId) {
              const boardAPI = await $template.useBoardAPI();
              currentBoard = await boardAPI.getCurrentBoard();
              console.log(currentBoard);
          }
          if (context.itemId) { // Important:  Weather app is configured in item view in case of getCurrentItem method
              currentItem = await itemAPI.getCurrentItem(); // to get current item
              currentItem.column_values.forEach((v) => {
                  currentItem[v.id] = v.value;
              });
              delete currentItem.column_values;
              console.log(currentItem);
          }
          const templateContext = { item: currentItem, board: currentBoard };
          renderVariables(templateContext);
          const updateHTML = _.debounce(function (content) {
              // const template = Handlebars.compile(\`<style>body{color:white;}</style>\${content}\`)
              const template = Handlebars.compile(\`\${content}\`)
              const htmlContent = template(templateContext);
              preview.srcdoc = htmlContent;
          }, 500);
  
          const quill = new Quill('#editor', {
              theme: 'snow'
          });
          quill.on('text-change', function () {
              const updated = quill.getContents()
              // console.log('Text change!',quill.root.innerHTML);
              const content = quill.root.innerHTML;
              updateHTML(content);
  
          });
      } catch (e) {
          console.error(e);
      }
      view.hideLoading();
  })`,
  css: `/** CSS */
  .wrapper {
  
      height: 65%;
  }
  
  #editor {
      height: 50%;
  }
  
  #preview {
      height: 50%;
  }
  
  body {
      padding: 20px;
      color: black;
      background-color: white;
      overflow-x: hidden;
      overflow-y: scroll;
  }
  
  .modal-dialog {
    width: 100%;
      margin: 0;
      top: 0;
      left: 0;
      max-width: none !important;
  }
  
  .modal-content {
    height: 99%;
  }
  .preview-card{
      margin-top: 15px;
  }`
};
