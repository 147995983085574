import { Button, Modal, Space, Spin, Tag, Tooltip, Tour, TourProps } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import {
  CloudUploadOutlined,
  SaveOutlined,
  RollbackOutlined,
  LoadingOutlined,
  FormatPainterOutlined
} from '@ant-design/icons';
import { TemplateService } from '../../../services/template.service';
import { CodeEditorIDE } from '../../ide/CodeEditorIDE';
import { NotificationService } from '../../../../system/services/ui/notification.service';
import { usePrompt } from '../../../../system/gists/use.prompt';
import { GtmService } from '../../../../system/services/stats/gtm.service';
import { PermissionService } from '../../../services/csp.service';

import { AclService, Permissions, UserInfo } from '../../../services/acl.service';
import { ReactIf } from '../../../../system/components/conditional/r.if';
import { MondayRuntime } from '../../../../monday/services/monday.runtime';
import { TourService } from '../../../../system/services/ui/tour.service';

const tourService = TourService.instance();
const notification = NotificationService.instance();
const templateService = TemplateService.instance();
const permissionService = PermissionService.instance();

const aclService = AclService.instance();

export function TemplateFormView() {
  const navigate = useNavigate();
  const location = useLocation();
  const [modal, contextHolder] = Modal.useModal();
  /**@type {any} */
  const ideRef = useRef(null);
  const { id } = useParams();

  const [userInfo, setUserInfo] = useState<UserInfo>({
    acl: { enabled: false, aclWeight: 0, permission: Permissions.viewer },
    user: null
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [template, setTemplate] = useState(templateService.emptyTemplate());
  const [cspSettings, setCSPSettings] = useState({ enableCSP: false, policies: [] });
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [showPublishedView, setShowPublishedView] = useState(
    searchParams.get('view') === 'published'
  );
  const templateLoaded = useRef(false);
  const sourceId = searchParams.get('sourceId');
  const [noUnsavedChanges, setNoUnsavedChanges] = useState(true);
  const [hideBackButton, setHideBackButton] = useState(
    searchParams.get('hideBackButton') === 'true'
  );
  const [openTour, setOpenTour] = useState(!tourService.isUserVisitedTour('template-form'));
  const tourRefs = {
    ide: useRef(null),
    settings: useRef(null),
    saveButton: useRef(null),
    publishButton: useRef(null),
    configureButton: useRef(null)
  };
  const steps: TourProps['steps'] = [
    {
      target: tourRefs.settings.current,
      description: 'You can configure the template settings here',
      title: 'Menu Bar'
    },
    {
      target: tourRefs.saveButton.current,
      description: 'Click here to save your changes',
      title: 'Save'
    },
    {
      target: tourRefs.publishButton.current,
      description: 'Click here to publish your template',
      title: 'Publish'
    },
    {
      target: tourRefs.configureButton.current,
      description: 'Click here to configure the template (e.g. name,tags, description))',
      title: 'Configure'
    },
    {
      target: tourRefs.ide.current,
      description: 'This is the IDE where you can edit the HTML, CSS, Javascript of your template',
      title: 'IDE'
    }
  ];
  usePrompt(
    !noUnsavedChanges ? 'You have unsaved changes. Are you sure you want to leave?' : false,
    {
      beforeUnload: true
    }
  );
  useEffect(() => {
    aclService
      .getUserInfo()
      .then((info) => {
        setUserInfo(info);
      })
      .catch((e) => {
        console.error(e);
      }); // loading userinfo
    // Adjusting the height of the iframe, IMPORTANT
    MondayRuntime.instance().adjustHeightBasedOnRootDiv();
  }, []);
  useEffect(() => {
    let cloned = false;
    let recordId = id;
    if ((!recordId || recordId === 'new') && sourceId) {
      // @ts-ignore
      recordId = sourceId;
      cloned = true;
    }
    if (recordId !== 'new') {
      (async () => {
        try {
          setLoading(true);
          const [template, csps] = await Promise.all([
            templateService.getTemplateById(recordId, showPublishedView),
            permissionService.getContentPolicies()
          ]);
          if (cloned) {
            template.id = null;
            template.name = `${template.name} (copy)`;
            template.publishedAt = null;
            template.createdAt = null;
            template.updatedAt = null;
          }
          setTemplate(template);
          setCSPSettings(csps);
          templateLoaded.current = true;
          GtmService.instance().pageview(`/templates/${recordId}/${template.name}`);
        } catch (e) {
          // navigate('/error', { state: { error: e } });
          console.error(e);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      templateLoaded.current = true;
    }
  }, [id, sourceId, showPublishedView, location]);
  const isNewTemplate = () => {
    return !id || id === 'new';
  };
  const publishTemplate = async () => {
    const key = 'publishing';
    NotificationService.instance().loading({
      key,
      message: 'Publishing...',
      duration: 0
    });
    const publishedTemplate = await TemplateService.instance().publishTemplate(template.id);
    setTemplate(publishedTemplate);
    NotificationService.instance().success({
      key,
      message: 'Published'
    });
  };
  const saveTemplate = async () => {
    if (ideRef.current) {
      const value = ideRef.current.getValue();
      if (!value.name) {
        ideRef.current.openPropDialog();
      } else if (!value.html && !value.css && !value.javascript) {
        notification.error({
          message: 'Please enter at least one of the HTML, CSS, Javascript'
        });
      } else {
        setSaveLoading(true);
        try {
          notification.loading({ message: 'Saving...', key: 'saving', duration: 0 });
          const saved = await templateService.upSertTemplate(value);
          // forcefully update the audi fields
          template.createdAt = saved.createdAt;
          template.updatedAt = saved.updatedAt;
          template.publishedAt = saved.publishedAt;
          setTemplate({ ...template });
          setNoUnsavedChanges(true);
          if (isNewTemplate()) {
            setTimeout(() => {
              notification.success({ message: 'Saved', key: 'saving' });
              setSaveLoading(false);
              navigate(`/templates/${saved.id}/edit`);
            }, 500); //imp to unblock the usePrompt
          } else {
            notification.success({ message: 'Saved', key: 'saving' });
            setSaveLoading(false);
          }
        } catch (e) {
          notification.success({ message: 'Error while saving ,Please retry', key: 'saving' });
          console.error(e);
        } finally {
          setSaveLoading(false);
        }
      }
      // console.log(value);
    }
  };
  const updateDetails = async (details) => {
    if (!isNewTemplate()) {
      await templateService.updateTemplate({ ...details, id }, true);
    }
  };

  return (
    <Spin tip="Loading..." size="small" spinning={loading} indicator={<LoadingOutlined />}>
      {contextHolder}
      <div className="form-wrapper" ref={tourRefs.ide}>
        <CodeEditorIDE
          hideBackButton={hideBackButton}
          cspSettings={cspSettings}
          readOnly={showPublishedView}
          ref={ideRef}
          record={template}
          onChange={(record) => {
            setNoUnsavedChanges(false);
            setTemplate(record);
          }}
          onDetailsChange={(details) => {
            updateDetails(details);
          }}
          onMenuItemClick={(key) => {}}
          templateLoaded={templateLoaded.current}
          headerActions={
            <Tag
              style={{ paddingRight: '10px', display: template.publishedAt ? 'block' : 'none' }}
              color="processing">
              <Tooltip title="Show Published version">
                <Button
                  size="small"
                  type="link"
                  style={{ display: showPublishedView ? 'none' : 'block' }}
                  onClick={() => {
                    navigate(`/templates/${template.id}/edit?view=published`);
                    setShowPublishedView(true);
                  }}>
                  Published {new Date(template.publishedAt).toLocaleString()}
                </Button>
              </Tooltip>
              <Tooltip title="Back to editing">
                <Button
                  icon={<RollbackOutlined />}
                  type="link"
                  style={{ display: showPublishedView ? 'block' : 'none' }}
                  onClick={() => {
                    navigate(`/templates/${template.id}/edit`);
                    setShowPublishedView(false);
                  }}>
                  Edit
                </Button>
              </Tooltip>
            </Tag>
          }
          actions={
            <Space className="form-action" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                ref={tourRefs.saveButton}
                type="default"
                disabled={showPublishedView || !aclService.canEdit()}
                icon={<SaveOutlined />}
                loading={loading || saveLoading}
                onClick={() => saveTemplate()}>
                Save
              </Button>
              <Button
                type="default"
                ref={tourRefs.publishButton}
                icon={<CloudUploadOutlined />}
                disabled={showPublishedView || !template.createdAt || !aclService.canEdit()}
                onClick={() => {
                  if (noUnsavedChanges === false) {
                    NotificationService.instance().warn({
                      message: 'Please save your changes before publishing'
                    });
                    return;
                  }
                  modal.confirm({
                    title: 'Are you sure?',
                    icon: <i className="fa fa-cloud-upload" />,
                    content:
                      'This will update all the pages using this template with the latest changes',
                    okText: 'Publish',
                    cancelText: 'Cancel',
                    onOk: () => {
                      publishTemplate();
                    }
                  });
                }}>
                Publish
              </Button>
              {/* <Button
                icon={<FormatPainterOutlined />}
                disabled={!template.publishedAt || !aclService.canEdit()}
                onClick={() => {
                  navigate(`/templates/${template.id}/configure`);
                }}>
                Configure
              </Button> */}
            </Space>
          }></CodeEditorIDE>
        {/* <Tour
          open={openTour}
          onClose={() => {
            setOpenTour(false);
            tourService.endTour('template-list');
          }}
          steps={steps}
        /> */}
      </div>
    </Spin>
  );
}
