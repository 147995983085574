import { EventBus } from '../../system/services/event/event.bus';
import { PanpalEventHandler } from '../../system/services/messaging/panpal.event.handler';
import { TemplateViewEventBus } from './event-buses';

export enum TemplateViewEvents {
  ShowLoading = 'view.showLoading',
  HideLoading = 'view.hideLoading',
  ShowAlert = 'view.showAlert',
  ShowModal = 'view.showModal',
  HideModal = 'view.hideModal',
  ShowNotification = 'view.showNotification',
  HideNotification = 'view.hideNotification'
}
export class TemplateViewEventHandler implements PanpalEventHandler {
  constructor(private context: any) {}
  sortOrder: number;
  getEventNames(): string[] {
    return Object.values(TemplateViewEvents);
  }
  async handle(event: string, payload: any): Promise<any> {
    return TemplateViewEventBus.emit(event, payload);
  }
}
