import { Space, Tabs } from 'antd';
import { CSPSettingsList } from './CspSettings.list';

import GroupList from './AclGroupComponent';
import AclConfigComponent from './AclConfigComponent';
import { useContext } from 'react';
import { UserAclProvider } from '../../../contexts';

export default function SettingsComponent(props = {}) {
  const userAclProvider = useContext(UserAclProvider);
  const items = [
    {
      key: 'CSPSettings',
      label: (
        <Space>
          <i className="fas fa-lock" />
          Content Settings
        </Space>
      ),
      children: <CSPSettingsList />
    }
  ];
  // if (!userAclProvider.userInfoApiError) {
  //   items.push({
  //     key: 'permissions',
  //     label: (
  //       <Space>
  //         <i className="fas fa-user-lock" />
  //         User Access
  //       </Space>
  //     ),
  //     children: <AclConfigComponent />
  //   });
  // }
  // return <CSPSettingsList />;
  return (
    <div className="settings-component" style={{ padding: '20px', paddingTop: 0 }}>
      <Tabs defaultActiveKey="1" items={items}></Tabs>
    </div>
  );
}
