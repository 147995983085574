/**css imports */
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.scss';
import './Theme.scss';

/**other impports */
import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import {
  Button,
  ConfigProvider,
  FloatButton,
  Result,
  Tooltip,
  message,
  notification,
  theme
} from 'antd';
import PropType from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotificationService } from './modules/system/services/ui/notification.service';
import { env } from './env';
import { GtmService } from './modules/system/services/stats/gtm.service';
import { MondayRuntime } from './modules/monday/services/monday.runtime';
import { useAsyncEffect } from './modules/system/hooks/use.async.effect';
import { ThemeEventBus, ThemeService, Themes } from './modules/system/services/ui/theme.service';
import { CookieBanner } from './modules/system/components/common/CookieBanner';
import Confetti from 'react-confetti';
import { TourEventBus } from './modules/system/services/ui/tour.service';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { AppSubscriptionService } from './modules/system/services/system/app.subscription.service';
import { AppInstallationService } from './modules/monday/services/app.installation.service';
import { SubscriptionComponent } from './SubscriptionComponent';

if (env.FORGE_ENV === 'prod') {
  GtmService.instance().enable().init(env.GA_TRACKING_ID);
}
// GtmService.instance().enable().init(env.GA_TRACKING_ID);
const queryClient = new QueryClient();
const runtime = MondayRuntime.instance();
const themeService = ThemeService.instance();
const appInstallation = AppInstallationService.instance();
function App({ children }) {
  const [api, notificationContextHolder] = notification.useNotification();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [theme, setTheme] = React.useState(themeService.getTheme());
  const [context, setContext] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const currentThemeRef = useRef(themeService.getThemeName());
  const [isInFullscreen, setFullScreen] = useState(false);
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );
  const switchTheme = (themeName) => {
    themeName = themeService.sanitizeThemeName(themeName);
    if (themeName !== currentThemeRef.current) {
      setTheme(themeService.switchTheme(themeName as Themes));
      document.body.classList.remove(`theme-${currentThemeRef.current}`);
      document.body.classList.add(`theme-${themeService.getThemeName()}`);
      currentThemeRef.current = themeService.getThemeName();
    }
  };
  useAsyncEffect(async () => {
    NotificationService.instance().init({ api, messageApi });
    runtime.listen('context', (context) => {
      if (context.data?.theme) {
        switchTheme(context.data.theme);
      }
    });
    TourEventBus.subscribe((event, { show, duration }) => {
      if (event.name === 'confetti.change') {
        setShowConfetti(show);
        if (show) {
          setTimeout(() => {
            setShowConfetti(false);
          }, duration);
        }
      }
    });
  }, []);
  useAsyncEffect(async () => {
    let themeName = searchParams.get('theme');
    const context = await runtime.getContext();
    if (runtime.isRunningInMonday()) {
      themeName = context.theme;
    }
    if (themeName && themeName !== themeService.getThemeName()) {
      switchTheme(themeName);
    }
    setContext(context);
    await appInstallation.initInstallation();
    // console.log('context', context);
  }, [searchParams]);

  if (context?.user?.isViewOnly) {
    return (
      <Result status="warning" title="View mode" subTitle="Unable to show template in view mode" />
    );
  }
  return (
    <div className={`app theme-${themeService.getThemeName()}`}>
      <Tooltip title="Toggle full screen">
        <FloatButton
          className="full-screen-button"
          icon={<i className={isInFullscreen ? 'fa fa-compress' : 'fa fa-expand'}></i>}
          onClick={() => {
            if (isInFullscreen) {
              document.exitFullscreen();
              setFullScreen(false);
            } else {
              document.documentElement.requestFullscreen();
              setFullScreen(true);
            }
          }}
        />
      </Tooltip>
      <CookieBanner />
      <div className="confetti-wrapper" style={{ display: showConfetti ? 'block' : 'none' }}>
        <Confetti run={showConfetti} />
      </div>
      <ConfigProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          {notificationContextHolder}
          {messageContextHolder}
          <SubscriptionComponent>{children}</SubscriptionComponent>
        </QueryClientProvider>
      </ConfigProvider>
    </div>
  );
}
App.propTypes = {
  children: PropType.any
};
export default App;
