import { MondayItemApi } from '../../monday/services/monday.item.api';
import { MondayRuntime } from '../../monday/services/monday.runtime';
import { PanpalEventHandler } from '../../system/services/messaging/panpal.event.handler';
const itemAPI = MondayItemApi.instance();
export class ItemEventHandler implements PanpalEventHandler {
  constructor(private context: any) {}
  sortOrder = 0;
  getEventNames(): string[] {
    return [
      'item.findById',
      'item.getCurrentItem',
      'item.createItem',
      'item.updateItem',
      'item.deleteItem'
    ];
  }

  async handle(event: string, payload: any) {
    // console.log('item', payload);
    switch (event) {
      case 'item.findById':
        return itemAPI.findItemById(payload.id);
      case 'item.getCurrentItem':
        const context = await MondayRuntime.instance().getContext();
        return itemAPI.findItemById(context.itemId);
      case 'item.createItem':
        return itemAPI.createItem(payload.boardId, payload.groupId, payload.name, payload.fields);
      case 'item.updateItem':
        return itemAPI.updateItem(payload.boardId, payload.id, payload.fields);
      case 'item.deleteItem':
        return itemAPI.deleteItem(payload.id);
    }
    return payload;
  }
}
