export default {
  name: 'Youtube',
  description: `The YouTube HTML Template is a pre-built template that allows users to easily embed YouTube videos . It provides configuration for responsive sizing to ensure that videos look great on any device.`,
  category: 'Google / Youtube',
  tags: ['video', 'youtube'],
  active: true,
  icon: 'fa-solid fa-youtube',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe width="100%" height="100%" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
  script: `/** JavaScript */
  $template.ready(async () => {
    const iframe = document.getElementsByTagName('iframe')[0];
    const view = $template.useView();
    view.showLoading();
    iframe.onload = () => {
      view.hideLoading();
    };
    iframe.onerror = () => {
      view.hideLoading();
      // view.showAlert('Error loading video', 'danger');
    }
    const variables = $template.useVariables(); // get variables service
    const url = generateEmbedUrl(await variables.getValue('url'));
    iframe.src = url;
    
    function generateEmbedUrl(videoInput) {
      let videoId = videoInput;
  
      // Extract video ID from different URL formats
      if (videoInput.includes('youtube.com/watch?v=')) {
        videoId = videoInput.split('v=')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
          videoId = videoId.substring(0, ampersandPosition);
        }
      } else if (videoInput.includes('youtu.be/')) {
        videoId = videoInput.split('youtu.be/')[1];
        const ampersandPosition = videoId.indexOf('&');
        if (ampersandPosition !== -1) {
          videoId = videoId.substring(0, ampersandPosition);
        }
      } else if (videoInput.includes('/embed/')) {
        const embedPosition = videoId.indexOf('/embed/');
        videoId = videoId.substring(embedPosition + 7);
  
      }
      return 'https://www.youtube.com/embed/'+videoId;
    }
  });`,
  css: ``,
  variables: [
    {
      label: 'Youtube Video URL',
      name: 'url',
      type: 'multilineString',
      defaultFieldValue: 'https://www.youtube.com/embed/jNQXAC9IVRw',
      mandatory: true,
      description: 'Youtube video Embed URL',
      defaultVariable: true
    }
  ]
};
