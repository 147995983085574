import React from 'react';
import { Form, Input, Select, Checkbox } from 'antd';
import { Variable } from '../../variable-config/variable.form';
import { FormItemByTypeRenderer } from '../../variable-config/FormItemByTypeRenderer';
import { useDebounce } from '../../../../system/hooks/useDebounce';

export type VariableType = 'text' | 'select' | 'checkbox'; // You can expand this based on your requirements

interface VariableFormProps {
  variables: Variable[];
  onSubmit: (values: any) => void;
  onChange: (variables: Variable[]) => void;
  onInit: (values: any) => void;
}

const VariableConfiguration: React.FC<VariableFormProps> = ({
  variables,
  onInit,
  onSubmit,
  onChange
}: VariableFormProps) => {
  const [form] = Form.useForm<Variable>();
  const onChangeDebounce = useDebounce(500);
  onInit(form);
  return (
    <Form
      className="variable-form"
      onFinish={onSubmit}
      onChange={() => {
        onChangeDebounce(() => {
          onChange(variables.map((v) => ({ ...v, value: form.getFieldValue(v.name) })));
        });
      }}
      form={form}
      layout="vertical"
      labelCol={{ span: 20 }}
      wrapperCol={{ span: 24 }}>
      {variables.map((variable, index) => {
        return (
          <Form.Item
            help={variable.description}
            key={index}
            name={variable.name}
            label={variable.label}
            initialValue={variable.value}
            rules={[{ required: variable.mandatory, message: `${variable.label} is required!` }]}>
            <FormItemByTypeRenderer
              height={variable.settings?.height || 250}
              variable={variable}
              onChange={(changes) => {
                if (!changes.target) {
                  onChangeDebounce(() => {
                    onChange(variables.map((v) => ({ ...v, value: form.getFieldValue(v.name) })));
                  });
                }
              }}
            />
          </Form.Item>
        );
      })}
    </Form>
  );
};

export default VariableConfiguration;
