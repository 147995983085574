import { BeanManager } from '../../system/services/system/bean.manager';
import { MondayRuntime } from './monday.runtime';

const runtime = MondayRuntime.instance();
export const MondayBoardApi = BeanManager.register(
  class MondayBoardApi {
    async findBoardById(id: string) {
      const monday = runtime.getMonday();
      const result = await monday.api(
        `query {
        boards (ids: [${id}]) {name state    id    permissions}
        }`,
        { apiVersion: '2023-10' }
      );
      if (result.data.boards.length === 0) return null;
      return result.data.boards[0];
    }
    async findBoardByName(name: string) {
      const monday = runtime.getMonday();
      const result = await monday.api(
        `query {
        boards (limit: 25, name: "${name}") {name state    id    permissions}
        }`,
        { apiVersion: '2023-10' }
      );
      if (result.data.boards.length === 0) return null;
      return result.data.boards[0];
    }
    async findBoardsByName(name: string) {
      const monday = runtime.getMonday();
      const result = await monday.api(
        `query {
        boards (limit: 25, name: "${name}") {name state    id    permissions}
        }`,
        { apiVersion: '2023-10' }
      );
      return result.data.boards;
    }
    async findBoards() {
      const monday = runtime.getMonday();
      const result = await monday.api(
        `query {
        boards (limit: 25) {name state    id    permissions}
        }`,
        { apiVersion: '2023-10' }
      );
      return result.data.boards;
    }
    async createBoard(name: string, kind: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        create_board (board_name: "${name}", board_kind: ${kind}) {id}`,
        { apiVersion: '2023-10' }
      );
    }
    async duplicateBoard(id: string, name: string, duplicateType: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        duplicate_board (board_id: ${id}, board_name: "${name}", duplicate_type:${duplicateType} ) {name state    id    permissions}`,
        { apiVersion: '2023-10' }
      );
    }
    async updateBoard(id: string, attrName: string, attrValue: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        update_board (board_id: ${id}, board_attribute: $attrName, new_value: $attrValue) {name state    id    permissions}
        }`,
        { apiVersion: '2023-10', variables: { attrName, attrValue } }
      );
    }
    async archiveBoard(id: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        archive_board (board_id: ${id})
        }`,
        { apiVersion: '2023-10' }
      );
    }
    async deleteBoard(id: string) {
      const monday = runtime.getMonday();
      return monday.api(
        `mutation {
        delete_board (board_id: ${id}){id}
        }`,
        { apiVersion: '2023-10' }
      );
    }
  }
);
