import { BaseStorageEntity } from '../../monday/entities/base.storage.entity';
import { KeyValueStorage } from '../../monday/entities/key.value.entity';
import { StorageType } from '../../monday/storage/storage.type';
import { Variable } from '../components/variable-config/variable.form';
export interface HtmlProps {
  html: string;
  css: string;
  javascript: string;
  variables: Variable[];
}

export class HtmlTemplateEntity extends BaseStorageEntity<HtmlTemplateEntity> {
  static TableName = 'html_template';
  static StorageType = StorageType.Simple;

  public domPurifyFields = ['name', 'description', 'tags', 'html', 'css', 'javascript'];

  public fields = BaseStorageEntity.defineFields([
    'id',
    'name',
    'description',
    'active',
    'tags',
    'publishedAt',
    'createdAt',
    'updatedAt',
    'createdById',
    'updatedById',
    'publishedAt'
  ]);
  type: 'example' | 'user_defined' = 'user_defined';
  name: string;
  description?: string;
  html: string;
  active?: boolean;
  htmlId?: string;
  cssId?: string;
  tags?: string[];
  javascriptId?: string;
  $htmlId?: KeyValueStorage<HtmlProps>;
  $phtmlId?: KeyValueStorage<HtmlProps>;
  publishedAt?: Date;
  variables?: Variable[];
  pVariables?: Variable[];
  async toStorage(): Promise<Record<string, any>> {
    return { ...(await super.toStorage()), publishedAt: this.publishedAt?.getTime() };
  }
  async fromStorage(storage: Record<string, any>): Promise<this> {
    await super.fromStorage(storage);
    if (storage.publishedAt) {
      this.publishedAt = new Date(storage.publishedAt);
    }
    return this;
  }
}
