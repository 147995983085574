import React from 'react';
import { Table, Space, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface Variable {
  name: string;
  type: string;
  description?: string;
  defaultValue?: string;
  mandatory: boolean;
}

interface VariableListProps {
  variables: Variable[];
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  heighlightIndex: number;
}

const VariableList: React.FC<VariableListProps> = ({
  variables,
  onEdit,
  onDelete,
  heighlightIndex
}) => {
  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Default Value',
      dataIndex: 'defaultFieldValue',
      key: 'defaultFieldValue'
    },
    {
      title: 'Mandatory',
      dataIndex: 'mandatory',
      key: 'mandatory',
      render: (mandatory: boolean) => <>{mandatory ? 'Yes' : 'No'}</>
    },
    {
      title: 'Default',
      dataIndex: 'defaultVariable',
      key: 'defaultVariable',
      render: (defaultVariable: boolean) => <>{defaultVariable ? 'Yes' : 'No'}</>
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, variable: Variable, index: number) => (
        <Space size="middle">
          <Button type="link" icon={<EditOutlined />} onClick={() => onEdit(index)}></Button>
          <Popconfirm
            title="Are you sure to delete this variable?"
            onConfirm={() => {
              onDelete(index);
            }}>
            <Button type="link" danger icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <Table
      rowKey="name"
      columns={columns}
      dataSource={variables}
      rowClassName={(record, index) => (index === heighlightIndex ? 'table-row-highlight' : '')}
    />
  );
};

export default VariableList;
