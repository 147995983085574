import React from 'react';
import { Form, Checkbox, Button, Card } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { ITemplateConfig } from '../../../model/itemplate.config';
import { StyleComponent } from './StyleComponent';

export interface IOtherConfigurationsProps {
  config: ITemplateConfig;
  onChange: (config: ITemplateConfig) => void;
}
export function OtherConfigurations({ config, onChange }: IOtherConfigurationsProps) {
  const [form] = Form.useForm<ITemplateConfig>();
  return (
    <Form
      form={form}
      name="config_form"
      layout="horizontal"
      initialValues={{
        enableConfigAction: config.enableConfigAction,
        styleSettings: config.styleSettings,
        enableFullScreen: config.enableFullScreen
      }}
      onChange={(e) => {
        onChange({
          ...config,
          ...form.getFieldsValue()
        });
      }}>
      <Form.Item
        name="enableConfigAction"
        valuePropName="checked"
        label={<span>Show Config Button</span>}>
        <Checkbox></Checkbox>
      </Form.Item>
      <Form.Item
        name="enableFullScreen"
        valuePropName="checked"
        label={<span>Show Full Screen Button</span>}>
        <Checkbox></Checkbox>
      </Form.Item>
    </Form>
  );
}
