import { Button, List, Space, Tabs, Tooltip, Typography } from 'antd';
import { MondayRuntime } from '../../../../monday/services/monday.runtime';
import { env } from '../../../../../env';

const liStyle = {
  listStyle: 'none'
};

export default function HelpComponent() {
  const itmes = [
    {
      title: 'Watch Demo',
      href: env.LINKS.demo,
      icon: 'fa fa-video'
    },
    {
      title: 'Documentation',
      href: env.LINKS.documentation,
      icon: 'fa fa-book'
    },
    {
      title: 'Report an Issue',
      href: env.LINKS.reportIssue,
      icon: 'fa fa-envelope'
    },
    {
      title: 'Privacy Policy',
      href: env.LINKS.privacy,
      icon: 'fa fa-lock'
    },
    {
      title: 'Terms of Service',
      href: env.LINKS.terms,
      icon: 'fa fa-file-text'
    },
    {
      title: 'Contact Us',
      href: env.LINKS.contactUs,
      icon: 'fa fa-phone'
    },
    { title: 'Website', href: env.LINKS.website, icon: 'fa fa-globe' }
    // {
    //   title: 'Rate US',
    //   href: env.LINKS.rateUs,
    //   icon: 'fa fa-star',
    // }
  ];
  return (
    <div style={{ padding: '10px' }}>
      <ul style={{ paddingLeft: '0' }}>
        <li style={liStyle}>
          {itmes.map((item) => {
            return (
              <div className="full-width" style={{ width: '100%', display: 'flex' }}>
                <Space size={25} style={{ width: '95%' }}>
                  <i className={item.icon}></i>
                  <span>{item.title}</span>
                </Space>
                <div style={{ paddingTop: '5px' }}>
                  <Tooltip title="Open in new tab">
                    <Button
                      key={item.title}
                      type="link"
                      onClick={(e) => {
                        e.preventDefault();
                        MondayRuntime.instance().getRouter().open(item.href);
                      }}>
                      <i className="fa fa-external-link" aria-hidden="true"></i>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </li>
      </ul>
    </div>
  );
}
