import { useNavigate } from 'react-router-dom';
import { ResolverApiRoutes } from '../../../api.routes';
import { BeanManager } from '../../system/services/system/bean.manager';
import { TableNames } from '../model/table.names';
import { StorageRepository } from '../../monday/storage/storage.repository';
import { HtmlProps, HtmlTemplateEntity } from '../entities/html.template.entity';
import { KeyValueStorage } from '../../monday/entities/key.value.entity';
import { ReflectionUtil } from '../../system/utils/reflection.util';
import { ITemplateEntity } from '../model/itemplate';
import { MondayRuntime, StorageLevel } from '../../monday/services/monday.runtime';
import { getExampleTemplateById } from '../templates/index';
import { ITemplateConfig } from '../model/itemplate.config';
import {
  UserJourneyEvent,
  UserJourneyService
} from '../../system/services/ui/user.journey.service';

const templateRepository = new StorageRepository(HtmlTemplateEntity);
const keyValueRepository = new StorageRepository(KeyValueStorage<HtmlProps>);
export const TemplateTableName = TableNames.HtmlTemplate;
const TemplateQueryMethod = {
  GET_EXAMPLES: 'getExamples',
  GET_ALL: 'getAll'
};
export const TagColors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple'
];
const runtime = MondayRuntime.instance();
const userJourney = UserJourneyService.instance();
export const DEFAULT_TEMPLATE_CONFIG: ITemplateConfig = {
  styleSettings: {
    overflow: 'hidden'
  },
  enableConfigAction: true,
  enableFullScreen: true,
  variables: []
};
export const TemplateService = BeanManager.register(
  class TemplateService {
    generateHtmlId(templateId: string) {
      return `html:${templateId}`;
    }
    generatePHtmlId(templateId: string) {
      return `phtml:${templateId}`;
    }
    async getTemplateConfig(): Promise<ITemplateConfig> {
      const config = await runtime.getItem('TemplateConfig', StorageLevel.INSTANCE);
      return Object.assign({}, DEFAULT_TEMPLATE_CONFIG, config || {});
    }
    async saveTemplateConfig(config: ITemplateConfig) {
      return runtime.setItem('TemplateConfig', config, StorageLevel.INSTANCE);
    }
    emptyTemplate(): ITemplateEntity {
      return {
        name: '',
        description: '',
        html: '<!-- HTML -->',
        css: '/** CSS */',
        javascript: '/** JavaScript */',
        active: true,
        htmlId: null,
        cssId: null,
        javascriptId: null,
        variables: [],
        type: 'user_defined'
      };
    }
    constructor() {}
    getTagColor(tagName: string) {
      return TagColors[(tagName.charCodeAt(0) % TagColors.length) - 1];
    }
    serialize(template: ITemplateEntity): HtmlTemplateEntity {
      return new HtmlTemplateEntity({
        ...template,
        $htmlId: new KeyValueStorage<HtmlProps>({
          id: this.generateHtmlId(template.id),
          value: {
            html: template.html,
            css: template.css,
            javascript: template.javascript,
            variables: template.variables
          }
        }),
        $phtmlId: null
      });
    }
    unserialize(template: HtmlTemplateEntity): ITemplateEntity {
      const $htmlId = template.$htmlId;
      return {
        ...template,
        html: $htmlId.value.html,
        css: $htmlId.value.css,
        javascript: $htmlId.value.javascript,
        variables: ($htmlId.value.variables || []).map((v) => ({
          ...v,
          settings: v.settings || {}
        }))
      };
    }
    async deleteTemplate(template: HtmlTemplateEntity) {
      await templateRepository.delete(template.id);
      await keyValueRepository.delete(this.generateHtmlId(template.id));
      await keyValueRepository.delete(this.generatePHtmlId(template.id));
    }
    async getAllExamples(): Promise<any[]> {
      const { getExampleTemplates } = await import('../templates/index');
      return getExampleTemplates();
    }
    async getAllTemplates(
      query: { includeExamples?: boolean } = {}
    ): Promise<HtmlTemplateEntity[]> {
      let examples = [];
      if (query.includeExamples) {
        examples = await this.getAllExamples();
      }
      const templates = await templateRepository.findAll(query);
      return templates.concat(examples).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name === b.name) {
          return 0;
        }
        return -1;
      });
    }
    // This can return both published and unpublished templates based on the published flag
    async getTemplateById(
      id: string,
      published = false,
      { includeExamples = true } = {}
    ): Promise<ITemplateEntity> {
      if (includeExamples) {
        const examples = await getExampleTemplateById(id, true);
        if (examples) {
          return ReflectionUtil.clone(examples);
        }
      }
      const template = await templateRepository.findById(id);
      if (!template) {
        return null;
      }
      let htmlId = this.generateHtmlId(id);
      if (published) {
        htmlId = this.generatePHtmlId(id);
      }
      template.$htmlId = await keyValueRepository.findById(htmlId);
      return this.unserialize(template);
    }
    async updateTemplate(
      template: ITemplateEntity,
      detailsOnly = false
    ): Promise<HtmlTemplateEntity> {
      userJourney.logEvent(UserJourneyEvent.TEMPLATE_UPDATED, {
        templateId: template.id
      });
      const serialized = this.serialize(template);
      await templateRepository.save(serialized);
      if (!detailsOnly) {
        serialized.$htmlId = await keyValueRepository.save(
          new KeyValueStorage<HtmlProps>({
            ...serialized.$htmlId,
            id: this.generateHtmlId(template.id)
          })
        );
      }
      return serialized;
    }
    async createTemplate(template: ITemplateEntity): Promise<HtmlTemplateEntity> {
      template.id = template.id || ReflectionUtil.uuid();
      userJourney.logEvent(UserJourneyEvent.TEMPLATE_CREATED, {
        templateId: template.id
      });
      const serialized = this.serialize(template);
      const $html = await keyValueRepository.save(
        new KeyValueStorage<HtmlProps>({
          ...serialized.$htmlId,
          id: this.generateHtmlId(template.id)
        })
      );
      return await templateRepository.save(serialized);
    }
    async upSertTemplate(template: ITemplateEntity): Promise<HtmlTemplateEntity> {
      if (template.id) {
        return await this.updateTemplate(template);
      }
      return await this.createTemplate(template);
    }
    async publishTemplate(templateId: string): Promise<ITemplateEntity> {
      const $html = await keyValueRepository.findById(`html:${templateId}`);
      const $phtml = new KeyValueStorage<HtmlProps>({
        ...ReflectionUtil.clone($html),
        id: this.generatePHtmlId(templateId),
        createdAt: new Date(),
        updatedAt: new Date()
      });
      const template = await templateRepository.findById(templateId);
      template.$phtmlId = $phtml;
      template.$htmlId = $html;
      await keyValueRepository.save($phtml);
      await templateRepository.save(template, { publishedAt: new Date() });
      userJourney.logEvent(UserJourneyEvent.TEMPLATE_PUBLISHED, {
        templateId
      });
      return this.unserialize(template);
    }
  }
);
