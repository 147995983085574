import { MondayRuntime } from '../../monday/services/monday.runtime';
import { PanpalEventHandler } from '../../system/services/messaging/panpal.event.handler';
import { Variable } from '../components/variable-config/variable.form';
import { ITemplateEntity } from '../model/itemplate';
import { VariableService } from '../services/variable.service';
const macroService = MondayRuntime.instance();
const variableService = VariableService.instance();
export class VariableEventHandler implements PanpalEventHandler {
  protected variables: Variable[];
  constructor(private context: { template: ITemplateEntity }) {
    this.variables = context.template.variables;
  }
  sortOrder: number;
  getEventNames(): string[] {
    return ['variable.get', 'variable.getValue', 'variable.asString'];
  }

  async handle(event: string, payload: any): Promise<any> {
    if (event === 'variable.asString') {
      return variableService.getVariableConfiguration(this.context.template);
    }
    const errorMessage = `Template Error | A variable with name "${payload.name}" is being used but not defined in the template.`;
    if (!this.context.template.variables || this.context.template.variables.length === 0) {
      throw new Error(errorMessage);
    }
    const variable = this.context.template.variables.find((v) => v.name === payload.name);
    variable.value = await variableService.getConfiguredVariableValue(
      this.context.template,
      payload.name
    );
    if (!variable) {
      throw new Error(errorMessage);
    }
    switch (event) {
      case 'variable.get':
        return variable;
      case 'variable.getValue':
        // default variable will have all configuration in the macro
        if (variable.value !== null && typeof variable.value !== 'undefined') {
          return variable.value;
        }
        if (Array.isArray(variable.defaultFieldValue) && variable.defaultFieldValue.length === 0) {
          return null;
        }
        return variable.defaultFieldValue;
        break;
      default:
        return new Error('No handler found for event ' + event);
    }
  }
}
