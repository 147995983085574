// .env file
export const env = {
  ENABLE_COOKIE_BANNER: false,
  SUBS_WHITELIST_ACCOUNTS: ['appssolutions-team'],
  MONDAY_APP_ID: 'com-appknitters-monday',
  GA_TRACKING_ID: 'G-NRJ3CCF4M7',
  FORGE_ENV: window.parent !== window ? 'prod' : 'mock',
  MOCK_ENDPOINT: 'http://localhost:3000',
  MOCK_ENV_CONTEXT: 'itemView',
  MOCK_CONTEXT_OVERRIDE: { user: { isViewOnly: false, isGuest: false } },
  LINKS: {
    demo: 'https://youtu.be/1zSyKeucaXY',
    documentation:
      'https://brainy-tea-da9.notion.site/170589b65e614aa9bf45b25ed8f5963e?v=542147657b4f4847979b5dc998f4b5af',
    reportIssue: 'https://appknitters.atlassian.net/servicedesk/customer/portal/2/group/12',
    privacy: 'https://www.appknitters.com/htmltemplateformonday/privacy-policy',
    terms: 'https://www.appknitters.com/htmltemplateformonday/terms-of-services',
    rateUs: '#',
    contactUs: 'https://www.appknitters.com/contact',
    website: 'https://www.appknitters.com'
  }
};
