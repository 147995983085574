import { MondayBoardApi } from '../../monday/services/monday.board.api';
import { MondayRuntime } from '../../monday/services/monday.runtime';
import { PanpalEventHandler } from '../../system/services/messaging/panpal.event.handler';
const boardAPI = MondayBoardApi.instance();
export class BoardEventHandler implements PanpalEventHandler {
  constructor(private context: any) {}
  sortOrder = 0;
  getEventNames(): string[] {
    return [
      'board.findById',
      'board.getCurrentBoard',
      'board.createBoard',
      'board.updateBoard',
      'board.deleteBoard'
    ];
  }

  async handle(event: string, payload: any) {
    // console.log('board', payload);
    switch (event) {
      case 'board.findById':
        return boardAPI.findBoardById(payload.id);
      case 'board.getCurrentBoard':
        const context = await MondayRuntime.instance().getContext();
        return boardAPI.findBoardById(context.boardId);
      case 'board.createBoard':
        return boardAPI.createBoard(payload.name, payload.fields);
      case 'board.duplicateBoard':
        return boardAPI.duplicateBoard(payload.id, payload.name, payload.duplicateType);
      case 'board.updateBoard':
        return boardAPI.updateBoard(payload.id, payload.fieldName, payload.fieldValue);
      case 'board.deleteBoard':
        return boardAPI.deleteBoard(payload.id);
    }
    return payload;
  }
}
