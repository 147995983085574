export default {
  name: 'CodePen Embed',
  description: 'Embed a CodePen pen on a webpage.',
  category: 'Code / CodePen',
  tags: ['code', 'codepen'],
  active: true,
  icon: 'fa-solid fa-code',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `
      <div id="embedContainer" style="width: 100%; height: 100%;">
      <iframe 
      id="embed-frame" 
      width="100%" height="100%" 
      style="width: 100%; height: 100%;" 
      frameborder="no">
        
      </iframe>
        <div id="error" style="display: none; color: red;"></div>
      </div>`,
  script: `/** JavaScript */
    $template.ready(async () => {
          const regexStandard =  new RegExp('^https:\\/\\/codepen.io\/([a-zA-Z0-9_-]+)\/pen\/([a-zA-Z0-9_-]+)');
          const regexEmbed =  new RegExp('^https:\/\/codepen.io\/(.*)\/embed\/(.*)');
          const variables = $template.useVariables();
          const penUrl = await variables.getValue('CodePen_Pen_URL');
          const iframe = document.getElementById('embed-frame');
          const view = $template.useView();
          view.showLoading();
          iframe.onload = () => {
            view.hideLoading();
          };
          iframe.onerror = () => {
            view.hideLoading();
            // view.showAlert('Error loading video', 'danger');
          }
          setTimeout(() => {
            view.hideLoading();
          }, 2000);
          const errorElement = document.getElementById('error');

          if (!penUrl) {
              errorElement.textContent = 'No CodePen Pen URL provided.';
              errorElement.style.display = 'block';
              return;
          }

          if (penUrl.match(regexEmbed)) {
              iframe.src = penUrl;
              errorElement.style.display = 'none';
              return;
          }

          const matchStandard = penUrl.match(regexStandard);
          if (matchStandard) {
              const embedUrl = 'https://codepen.io/'+matchStandard[1]+'/embed/'+matchStandard[2]+'?default-tab=html%2Cresult';
              iframe.src = embedUrl;
              errorElement.style.display = 'none';
              return;
          }

          // If not matched any of the above formats, show error:
          errorElement.textContent = 'Invalid CodePen Pen URL: ' + penUrl;
          errorElement.style.display = 'block';

    });`,
  css: `
      #error {
        font-size: 16px;
        padding: 10px;
        background-color: #f8d7da;
        color: #721c24;
        border: 1px solid #f5c6cb;
        border-radius: 4px;
        margin: 10px;
      }`,
  variables: [
    {
      label: 'CodePen Pen URL',
      name: 'CodePen_Pen_URL',
      type: 'multilineString',
      defaultFieldValue: 'https://codepen.io/team/codepen/embed/PNaGbb',
      mandatory: true,
      description: 'CodePen Pen URL',
      defaultVariable: true
    }
  ]
};
