import { MondayRuntime } from '../../monday/services/monday.runtime';
import { BeanManager } from '../../system/services/system/bean.manager';
import { TemplateEngine } from '../../system/utils/template.engine';
import { Variable } from '../components/variable-config/variable.form';
import { ITemplateEntity } from '../model/itemplate';
import { ITemplateConfig } from '../model/itemplate.config';
import { TemplateService } from './template.service';

const templateService = TemplateService.instance();
const runtime = MondayRuntime.instance();
export type ConfigValidationResult = (Variable & { configured: boolean })[];

export const VariableService = BeanManager.register(
  class VariableService {
    async getVariableConfiguration(template: ITemplateEntity): Promise<Record<string, any>> {
      return template.variables.reduce((acc, variable) => {
        acc[variable.name] = variable.value;
        return acc;
      }, {});
      const templateConfig = await templateService.getTemplateConfig();
      return templateConfig?.variables || {};
    }
    async validateVariables(
      template: ITemplateEntity,
      config: ITemplateConfig
    ): Promise<ConfigValidationResult> {
      const { variables } = template;
      return await Promise.all(
        variables.map(async (variable: Variable) => {
          return {
            ...variable,
            valid: true,
            configured: await this.isVariableConfigured(template, variable, config)
          };
        })
      );
    }

    async isVariableConfigured(
      template: ITemplateEntity,
      variable: Variable,
      config?: ITemplateConfig
    ): Promise<boolean> {
      if (!config) {
        config = await this.getVariableConfiguration(template);
      }
      return typeof config[variable.name] !== 'undefined';
    }

    async getConfiguredVariableValue(
      template: ITemplateEntity,
      name: string
    ): Promise<Record<string, any>> {
      const { variables } = template;
      const variable = variables.find((v) => v.name === name);
      if (!variable) {
        throw new Error('No variable found for name ' + name);
      }
      const config = await this.getVariableConfiguration(template);
      // return the value from the template if it is set otherwise return the value from the config
      // this is needed for configuration page
      const context = await runtime.getContext();
      const value = variable.value ? variable.value : config[name];
      if (typeof value === 'string') {
        return TemplateEngine.evalExpression(value, context);
      }
      return value;
    }
  }
);
