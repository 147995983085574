import { Layout, Tabs, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { Component } from 'react';
import ConfigEditorMenu from '../macro/macro-config/ConfigPopupMenu';
import { CodeEditor } from './CodeEditor';
import { FormatPainterOutlined, Html5Outlined, ThunderboltOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FullScreen } from '../../../system/components/fullscreen';
import VariableList from '../variable-config/variable.list';
import { VarialeCRUD } from '../variable-config/variable.crud';

const { Title } = Typography;

export interface SandboxProps {
  html: string;
  css: string;
  javascript: string;
  config: any;
  onChange: (change: any, type: string) => void;
  readOnly?: boolean;
  variables?: any[];
  shouldRender?: boolean;
}

export class Sandbox extends Component<SandboxProps> {
  shouldComponentUpdate(
    nextProps: Readonly<SandboxProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return nextProps.shouldRender;
  }
  render() {
    const {
      html,
      css,
      javascript,
      config,
      onChange,
      readOnly = false,
      variables = []
    } = this.props;

    const editors = [
      {
        key: 'html',
        label: (
          <span>
            <Html5Outlined />
            Html
          </span>
        ),
        children: (
          <CodeEditor
            editorOptions={{ readOnly }}
            defaultLanguage={'html'}
            value={html}
            onChange={_.debounce((html, event) => {
              onChange({ html, css, javascript, config }, 'html');
            }, 100)}
          />
        )
      },
      {
        key: 'javascript',
        label: (
          <span>
            <ThunderboltOutlined />
            Javascript
          </span>
        ),
        children: (
          <CodeEditor
            editorOptions={{ readOnly }}
            defaultLanguage={'javascript'}
            value={javascript}
            onChange={_.debounce((javascript, event) => {
              onChange({ html, css, javascript, config }, 'javascript');
            }, 100)}
          />
        )
      },
      {
        key: 'css',
        label: (
          <span>
            <FormatPainterOutlined />
            Css
          </span>
        ),
        children: (
          <CodeEditor
            editorOptions={{ readOnly }}
            defaultLanguage={'css'}
            value={css}
            onChange={_.debounce((css, event) => {
              onChange({ html, css, javascript, config }, 'css');
            }, 100)}
          />
        )
      },
      {
        key: 'Variables',
        label: (
          <span>
            <i style={{ marginRight: '10px' }} className="fa fa-wrench"></i>
            Variables
          </span>
        ),
        children: (
          <VarialeCRUD
            variables={variables}
            onChange={(changedVariables) => {
              onChange({ variables: changedVariables }, 'variables');
            }}
          />
        )
      }
    ];

    return (
      <FullScreen>
        <div className="sandbox-warpper">
          <Tabs defaultActiveKey="1" items={editors} />
        </div>
      </FullScreen>
    );
  }
}
