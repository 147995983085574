import { BaseStorageEntity } from '../entities/base.storage.entity';
import { IDataStorage } from '../ifs/idata.storage';
import { BigTableStorage } from './big.table.storage';
import { StorageType } from './storage.type';
import { TableStorage } from './table.storage';

export class StorageRepository<T extends BaseStorageEntity<T>> {
  protected storage: IDataStorage;
  constructor(
    public entityType: { new (attrs?: Record<string, any>): T } & {
      TableName: string;
      StorageType: StorageType;
    }
  ) {
    this.initStorage();
  }
  initStorage() {
    if (this.entityType.StorageType === StorageType.BigTable) {
      this.storage = new BigTableStorage(this.entityType.TableName);
    } else {
      this.storage = new TableStorage(this.entityType.TableName);
    }
  }
  async findById(id: string): Promise<T> {
    const record = await this.storage.findById(id);
    if (!record) {
      return null;
    }
    return new this.entityType().fromStorage(record); // not casting to T
  }
  async findAll(query: any): Promise<T[]> {
    const all = await this.storage.findAll(query);
    return Promise.all(all.map(async (e) => await new this.entityType().fromStorage(e)));
  }
  async save(entity: T, changes?: Partial<T>): Promise<T> {
    if (!entity) {
      entity = new this.entityType(changes);
    } else {
      Object.assign(entity, changes);
    }
    const result = await this.storage.upsert(await entity.toStorage());
    return new this.entityType().fromStorage(result);
  }
  async delete(id: string) {
    await this.storage.delete(id);
  }
}
