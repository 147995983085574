import { StorageType } from '../storage/storage.type';
import { BaseStorageEntity } from './base.storage.entity';

export class KeyValueStorage<T> extends BaseStorageEntity<KeyValueStorage<T>> {
  static TableName = 'KeyValueStorage';
  static StorageType = StorageType.BigTable;
  public fields: string[] = BaseStorageEntity.defineFields(['key', 'value']);
  public key: string;
  public value: T;
  public domPurifyFields = ['key', 'value'];
  constructor(attrs?: Partial<KeyValueStorage<T>>) {
    super(attrs);
    if (!attrs?.id && attrs?.key) {
      this.id = attrs.key;
    }
  }
}
