import { Alert, Button, Result } from 'antd';
import { useState } from 'react';
import { MondayRuntime } from './modules/monday/services/monday.runtime';
import { useAsync } from 'react-use';
import { useAsyncEffect } from './modules/system/hooks/use.async.effect';
import { AppSubscriptionService } from './modules/system/services/system/app.subscription.service';

const runtime = MondayRuntime.instance();
const subsService = AppSubscriptionService.instance();

export function SubscriptionComponent({ children }) {
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [shouldShowExtendedSubscription, setShouldShowExtendedSubscription] = useState(false);
  const openPlanSelection = () => {
    runtime.execute('openPlanSelection', { isInPlanSelection: false });
  };
  useAsyncEffect(async () => {
    setSubscriptionExpired(await subsService.isSubscriptionExpired());
    setShouldShowExtendedSubscription(await subsService.shouldShowExtendSubscription());
  }, []);
  if (!subscriptionExpired) {
    return <div>{children}</div>;
  } else if (shouldShowExtendedSubscription) {
    return (
      <div>
        <Alert
          message="Your subscription has expired. Please renew your subscription to continue using this app."
          banner
          closable
          action={
            <Button size="small" onClick={openPlanSelection}>
              Renew
            </Button>
          }
        />
        {children}
      </div>
    );
  } else {
    return (
      <Result
        status="error"
        title="Subscription expired"
        subTitle="Please renew your subscription to continue using the app"
        extra={
          <Button
            type="primary"
            icon={<i className="fas fa-shopping-cart"></i>}
            onClick={() => {
              openPlanSelection();
            }}>
            Renew
          </Button>
        }
      />
    );
  }
}
