import { BigTableStorage } from './big.table.storage';
import { TableStorage } from './table.storage';

export enum StorageType {
  Simple = 'simple',
  BigTable = 'big'
}
export const StorageTypeMap = {
  [StorageType.Simple]: TableStorage,
  [StorageType.BigTable]: BigTableStorage
};
