import { Form, Input, Select, Switch } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

export interface CodeEditorInfoFormProps {
  record?: any;
  onSubmit?: (record: any, isValid: boolean) => void;
}
export const CodeEditorInfoForm = forwardRef<any, CodeEditorInfoFormProps>(
  ({ record, onSubmit }, ref) => {
    onSubmit = onSubmit || function () {};
    const [tags, setTags] = useState([]);
    const [form] = Form.useForm();
    const [active, setActive] = useState(record.active === true);
    useEffect(() => {
      form.setFieldsValue(record);
      setActive(record.active === true);
    }, [record]);

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        return form.submit();
      }
    }));

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={record}
        onFinish={(changes) =>
          onSubmit(
            { ...record, ...changes },
            // @ts-ignore
            form.getFieldsError()?.filter((e) => e.errors.length > 0).length === 0
          )
        }
        onFinishFailed={() => {}}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input template name!'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea rows={4} placeholder="Enter a description" />
        </Form.Item>
        {/* <Form.Item label="Active" name="active" valuePropName="checked" style={{ display: 'flex' }}>
        <Switch />
      </Form.Item> */}
        <Form.Item label="Tags" name="tags">
          <Select
            mode="tags"
            tokenSeparators={[',']}
            options={tags.map((t) => ({ label: t, value: t }))}
          />
        </Form.Item>
      </Form>
    );
  }
);
CodeEditorInfoForm.displayName = 'CodeEditorInfoForm';
CodeEditorInfoForm.propTypes = {
  record: PropTypes.any,
  onSubmit: PropTypes.func
};
