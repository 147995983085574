export default {
  name: 'Google Slides',
  description: 'Embed a Google Slides presentation .',
  category: 'Google / Slides',
  tags: ['presentation', 'google slides'],
  active: true,
  icon: 'fa-solid fa-file-powerpoint',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe src="" width="100%" height="100%" frameborder="0"></iframe>`,
  script: `$template.ready(async () => {
    const iframe = document.getElementsByTagName('iframe')[0];
    const view = $template.useView();
    view.showLoading();
    iframe.onload = () => {
      view.hideLoading();
    };
    iframe.onerror = () => {
      view.hideLoading();
      // view.showAlert('Error loading video', 'danger');
    }
    const variables = $template.useVariables();
    const url = await variables.getValue('Your_Document_URL');
    iframe.src = url;
  });  
  `,
  css: ``,
  variables: [
    {
      label: 'Document URL',
      name: 'Your_Document_URL',
      type: 'multilineString',
      defaultFieldValue:
        'https://docs.google.com/presentation/d/1dBxIzbpcm7C41DOQP9Aq13Pv_jXtBknPdjlPuasPksw/edit?usp=sharing',
      mandatory: true,
      description: 'Google Slide Document URL',
      defaultVariable: true
    }
  ]
};
