import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import { Drawer, Skeleton, message } from 'antd';
import htmlService from '../../services/html.service';
// @ts-ignore
import { ReflectionUtil } from '../../../system/utils/reflection.util';
import { EditorConsole } from './EditorConsole';
import { Allotment } from 'allotment';
import { CSPContext } from '../../contexts';
import { useAsyncEffect } from '../../../system/hooks/use.async.effect';
import { PermissionService } from '../../services/csp.service';
import { ConnectToIframe, RegisterPanpalHandlers } from '../../panpal-handlers';
import { ITemplateEntity } from '../../model/itemplate';
import _ from 'lodash';
import { useDebounce } from '../../../system/hooks/useDebounce';
import { MondayRuntime } from '../../../monday/services/monday.runtime';
import { TemplateViewEvents } from '../../panpal-handlers/view.event.handler';
import { TemplateViewEventBus } from '../../panpal-handlers/event-buses';
const permissionService = PermissionService.instance();

export interface IframeRendererProps {
  debounce?: number;
  template: ITemplateEntity;
  iframeRef?: any;
  onFrameContentRefresh?: () => void;
  autoExecute?: boolean;
  execute?: number;
  onExecute?: () => void;
  onConsoleMessage?: (msg: { args: any[]; level: string }) => void;
  view?: 'dev' | 'published';
  cspSettings: { enableCSP: boolean; policies: any[] };
  bodyStyle?: React.CSSProperties;
}
export default function IframeRenderer({
  template,
  cspSettings,
  iframeRef,
  onFrameContentRefresh,
  autoExecute,
  execute,
  onExecute,
  onConsoleMessage,
  view = 'dev',
  bodyStyle = {},
  debounce = 0
}: IframeRendererProps) {
  const [loading, setLoading] = useState(false);
  const invokeWithDebounce = useDebounce(debounce);
  // console.log('IframeRenderer', { template });
  onExecute = onExecute || function () {};
  onConsoleMessage = onConsoleMessage || function () {};
  /** @type {any} */
  const { html, css, javascript } = template;
  if (view === 'published') {
    // html = template.$;
  }
  const iframeParentDivRef = iframeRef || useRef(null);
  const splitPaneRef = useRef(null);
  // const [cspSettings, setCSPSettings] = useState({ enableCSP: false, policies: [] });

  // useAsyncEffect(async () => {
  //   const { enableCSP, policies } = await permissionService.getContentPolicies();
  //   setCSPSettings({ enableCSP, policies });
  // }, []);

  // const getIframeElement = (): HTMLElement & { contentWindow: Window & { console: Console } } => {
  //   return iframeParentDivRef?.current?.firstChild;
  // };
  // const getIframeWindow = () => {
  //   const iframe = getIframeElement();
  //   return iframe ? iframe.contentWindow : null;
  // };
  // const getIframeConsole = () => {
  //   const win = getIframeWindow();
  //   return win ? win.console : console;
  // };
  useEffect(() => {
    TemplateViewEventBus.subscribe((event, payload) => {
      switch (event.name) {
        case TemplateViewEvents.ShowLoading:
          setLoading(true);
          break;
        case TemplateViewEvents.HideLoading:
          setLoading(false);
          break;
      }
    });
  }, []);
  const buildContext = () => {
    return {
      // template,
      // runtime: ForgeRuntime.instance(),
      // parentWindow: window
      baseAssetPath: MondayRuntime.instance().getAssetURL('/assets')
    };
  };

  const interceptConsoleError = (iframe) => {
    const win = iframe.contentWindow;
    const doc = win.document;
    const methods = ['log', 'error', 'trace', 'time', 'warn', 'info', 'debug'];
    methods.forEach((method) => {
      const original = win.console[method].bind(win.console);
      win.console[method] = function (...args) {
        let stackPieces = [''];
        const error = new Error('Console Error');
        stackPieces = error.stack.split('\n');
        onConsoleMessage({ args, level: method });
        return original.call(
          win.console,
          ...args,
          '[' + stackPieces[stackPieces.length - 1].trim() + ']'
        );
      };
    });
  };

  const renderUIInFrame = async (iframe) => {
    const win = iframe.contentWindow;
    try {
      htmlService.instance().appenHtmlToIframe(iframe, {
        html,
        javascript,
        css,
        csps: cspSettings,
        context: buildContext(),
        bodyStyle
      });

      // await htmlService.instance().appendHtmlUnsafe(doc, { html, css, csps: cspSettings });
      await ConnectToIframe(iframe);
      // await htmlService.instance().appendScript(doc, javascript);
      // htmlService.instance().interceptListeners(doc);

      // htmlService.instance().dispatchDocEvent(doc, 'DOMContentLoaded');
      // onFrameContentRefresh && onFrameContentRefresh();
      //  htmlService.instance().appendScript(doc, javascript).appendStyle(doc, css); //this should be passed as html
    } catch (e) {
      console.error(e);
      onConsoleMessage({ args: [e], level: 'error' });
    } finally {
      // document.documentElement.style.height = 'fit-content';
      setTimeout(() => {
        onFrameContentRefresh && onFrameContentRefresh();
      }, 100); // imp as html rendering may take some time
    }
  };
  const renderTemplate = async () => {
    if (iframeParentDivRef.current) {
      RegisterPanpalHandlers({ template });
      // console.log('IframeRenderer debounced', { template });
      if (autoExecute || execute) {
        const iframe = htmlService
          .instance()
          .empty(iframeParentDivRef.current)
          .appendIframe(document, iframeParentDivRef.current, 'frame-renderer');
        interceptConsoleError(iframe);
        renderUIInFrame(iframe).finally(() => {
          onExecute();
        });
      }
    }
  };
  useEffect(() => {
    invokeWithDebounce(() => {
      renderTemplate();
    });
  }, [template, autoExecute, execute, cspSettings]);

  /**@type {any} */
  return (
    <div className="frame-wrapper" style={{ height: '100%' }}>
      <Skeleton loading={loading} active style={{ width: '100%', height: '100%' }}></Skeleton>
      <div
        style={{ visibility: loading ? 'hidden' : 'visible' }}
        ref={iframeParentDivRef}
        className="frame-main"></div>
    </div>
  );
}
