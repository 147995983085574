export default {
  name: 'Vimeo Video',
  description: `The Vimeo HTML Template is a pre-built template that allows users to easily embed Vimeo videos .`,
  category: 'Misc / Video',
  tags: ['video', 'vimeo'],
  active: true,
  icon: 'fa-solid fa-vimeo',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe title="vimeo-player" src="https://player.vimeo.com/video/76979871?h=8272103f6e" width="100%" height="100%" frameborder="0"    allowfullscreen></iframe>`,
  script: `/** JavaScript */
  $template.ready(async () => {
      const iframe = document.getElementsByTagName('iframe')[0];
      const view = $template.useView();
      view.showLoading();
      iframe.onload = () => {
        view.hideLoading();
      };
      iframe.onerror = () => {
        view.hideLoading();
        // view.showAlert('Error loading video', 'danger');
      }
      const variables = $template.useVariables(); // get variables service
      const url = await variables.getValue('url');
      const embedCode = getVimeoEmbedCode(url); // extract Vimeo video embed code from URL
      iframe.src = embedCode;
  });
  
  function getVimeoEmbedCode(url) {
      const regex = new Regex('/\/(\d+)/'); // extract video ID from URL
      const match = url.match(regex);
      if (match) {
          const videoId = match[1];
          return 'https://player.vimeo.com/video/' + videoId;
      }
      return url; // return the original URL if no valid Vimeo video ID is found
  }`,
  css: ``,
  variables: [
    {
      label: 'Vimeo Video URL',
      name: 'url',
      type: 'multilineString',
      defaultFieldValue: 'https://player.vimeo.com/video/76979871?h=8272103f6e',
      mandatory: true,
      description: 'Vimeo video Embed URL',
      defaultVariable: true
    }
  ]
};
