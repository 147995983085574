export default {
  name: 'Google Forms',
  description: 'Embed a Google Forms survey .',
  category: 'Google / Forms',
  tags: ['survey', 'google forms'],
  active: true,
  icon: 'fa-solid fa-file-alt',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<iframe src="" width="100%" height="100%" frameborder="0"></iframe>`,
  script: `$template.ready(async () => {
    const iframe = document.getElementsByTagName('iframe')[0];
    const view = $template.useView();
    view.showLoading();
    iframe.onload = () => {
      view.hideLoading();
    };
    iframe.onerror = () => {
      view.hideLoading();
      // view.showAlert('Error loading video', 'danger');
    }
    const variables = $template.useVariables();
    const url = await variables.getValue('Your_Document_URL');
    iframe.src = url;
  });  
  `,
  css: ``,
  variables: [
    {
      label: 'Document URL',
      name: 'Your_Document_URL',
      type: 'multilineString',
      defaultFieldValue: 'https://forms.gle/VJA4c9gw9uRRwXNh6',
      mandatory: true,
      description: 'Google form url',
      defaultVariable: true
    }
  ]
};
