import { KeyValueStorage } from '../entities/key.value.entity';
import { MondayRuntime } from './monday.runtime';
import { StorageRepository } from '../storage/storage.repository';
import { BeanManager } from '../../system/services/system/bean.manager';
import { ITemplateConfig } from '../../advance-html/model/itemplate.config';

const runtime = MondayRuntime.instance();
const keyValueRepository = new StorageRepository(KeyValueStorage<ITemplateConfig>);
export type ViewInstanceType =
  | 'item_view'
  | 'board_view'
  | 'dashboard'
  | 'notification'
  | 'workspace'
  | 'account'
  | 'user'
  | 'document'
  | 'unknown'
  | 'account_settings_view';
interface ConfigTargetObject {
  instanceType: ViewInstanceType;
  id: string;
}
export const MondayContextService = BeanManager.register(
  class MondayContextService {
    constructor() {}
    async getViewObject(): Promise<ConfigTargetObject> {
      const context = await runtime.getContext();
      if (context.instanceType === 'item_view') {
        return { instanceType: context.instanceType, id: context.boardId };
      }
      if (context.instanceType === 'board_view') {
        return { instanceType: context.instanceType, id: context.boardId };
      }
      if (context.instanceType === 'dashboard') {
        return { instanceType: context.instanceType, id: context.dashboardId };
      }
      throw new Error('Unknown view type');
    }
    async getTemplateConfig(): Promise<ITemplateConfig> {
      const context = await runtime.getContext();

      const settings = await runtime.getSettings();
      return settings.templateConfig;
    }
  }
);
