export default {
  name: 'Mermaid',
  description: `The Mermaid Template is a pre-built template that allows users to easily create and display mermaid diagrams.`,
  category: 'Misc / Charts',
  tags: ['misc', 'chart', 'mermaid'],
  active: true,
  icon: 'fa-solid fa-fish',
  created_by: 'system',
  updated_by: 'system',
  created_at: '2023-05-05',
  version: 0,
  html: `<!-- HTML -->
  <html>
  
  <body>
      <div id="render_container" style="width:100%;height:100%;">
          
      </div>
      <div id="graphDiv"></div>
      <script type="module">
          import  mermaid from 'https://cdn.jsdelivr.net/npm/mermaid@10/dist/mermaid.esm.min.mjs';
  window.mermaid=mermaid;
      </script>
  </body>
  
  </html>`,
  script: `/** JavaScript */
  $template.ready(async () => {
    const view = $template.useView();
    view.showLoading();  
     try{
      mermaid.initialize({ startOnLoad: false });
      const variables = await $template.useVariables();
      const code = await variables.getValue('graphDefinition');
      const drawDiagram = async function () {
          element = document.querySelector('#graphDiv');
          const { svg } = await mermaid.render('render_container', code);
          element.innerHTML = svg;
      };
      await drawDiagram();
    }catch(e){
      console.error(e);
    }finally{
      view.hideLoading();
    }
  }, ['mermaid'])`,
  css: `body{
    background-color: white;
  }`,
  variables: [
    {
      name: 'graphDefinition',
      type: 'script',
      defaultFieldValue: `gantt
      title A Gantt Diagram
      dateFormat  YYYY-MM-DD
      section Section
      A task           :a1, 2014-01-01, 30d
      Another task     :after a1  , 20d
      section Another
      Task in sec      :2014-01-12  , 12d
      another task      : 24d`,
      mandatory: true,
      defaultVariable: true,
      settings: {
        language: 'mermaid'
      },
      description: 'Mermaid Graph Definition'
    }
  ]
};
